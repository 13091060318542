// Chakra Imports
import React from "react";
import { Img, Box, Text, Button, Heading } from "@chakra-ui/react";

// Component Imports
import { useNavigate } from "react-router-dom";
import backIcon from "../../../images/common/back.png";
import goalOne from "../../../images/boarding/goal1.png";
import goalLine from "../../../images/boarding/goal_line.png";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight";
import goalSquareFetti from "../../../images/boarding/squarefetti.png";
import goalPaginationOne from "../../../images/boarding/goal_pagination_one.png";
import {
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../constants/color";

function Goals() {
	const navigate = useNavigate();
	const height = useResponsiveHeight();
	return (
		<Box
			h={height}
			p="30px"
			mx="auto"
			bg={syThemeBgYellowColor}
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
		>
			<Box>
				<Img
					src={backIcon}
					onClick={() => {
						navigate("/app");
					}}
				/>
			</Box>
			<Box textAlign={"center"} mt="4rem">
				<Heading fontWeight={"700"}>Set Your Goal</Heading>
				<Text mt="2rem" fontWeight={"500"}>
					Tailored just for you, and easy to follow.
				</Text>
				<Box mt="6rem" position="relative">
					<Img src={goalOne} mx="auto" />
					<Box position="absolute" top="10%" left="3%">
						<Img src={goalLine} />
					</Box>
					<Box position="absolute" bottom="25%" left="5%">
						<Img src={goalSquareFetti} />
					</Box>
					<Box position="absolute" bottom="10%" right="3%">
						<Img src={goalLine} />
					</Box>
				</Box>
				<Box mt="1rem">
					<Img mx="auto" src={goalPaginationOne} />
				</Box>
				<Box mt="3rem" textAlign={"center"}>
					<Button
						w="80%"
						bg={syThemeBgBlueColor}
						color={syWhite}
						_hover={{ bg: syThemeBgBlueColor }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							navigate("/rewards");
						}}
					>
						Next
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default Goals;
