import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Component Imports
import Goals from "../components/App/goal/Goals";
import Login from "../components/App/Login/Login";
import GoalOne from "../components/App/goal/GoalOne";
import GoalTwo from "../components/App/goal/GoalTwo";
import Boarding from "../components/App/boarding/Boarding";
import ScrollToTop from "../components/common/ScrollToTop";
import Register from "../components/App/register/Register";
import LandingPage from "../components/LandingPage/LandingPage";
import ForgotPassword from "../components/App/forgotPassword/ForgotPassword";
import Verification from "../components/App/verification/Verification";

// User Layout
import UserProtectedLayout from "./UserProtectedLayout.js";
import WelcomeUser from "../components/genzuser/WelcomeUser.js";

// Admin Layout
import AdminProtectedLayout from "./AdminProtectedLayout";
import PageNotFound from "../components/common/PageNotFound";
import Settings from "../components/admin/settings/Settings";
import AdminLayout from "../components/admin/AdminLayout.js";
import GoalList from "../components/admin/goal/GoalList.js";
import UsersList from "../components/admin/users/UsersList.js";
import AdminDashboard from "../components/admin/dashboard/AdminDashboard.js";
import DefaultPassword from "../components/App/defaultPassword/DefaultPassword.js";
import GoalDetails from "../components/genzuser/GoalDetails.js";
import Complete from "../components/genzuser/Complete.js";
import VerifyLandingPage from "../components/LandingPage/VerifyLandingPage.js";
import BetaUsers from "../components/admin/betaUsers/BetaUsers.js";
import CreateGoal from "../components/genzuser/goalStepper/CreateGoal.js";
import SetupGoal from "../components/genzuser/goalStepper/SetupGoal.js";
import GoalOverview from "../components/genzuser/goalStepper/GoalOverview.js";
import GoalProgress from "../components/genzuser/goalStepper/GoalProgress.js";
import UserProfile from "../components/genzuser/userProfile.js/UserProfile.js";
import DreamDashboard from "../components/genzuser/dashboard/DreamDashboard.js";
import GoalSummary from "../components/genzuser/goalStepper/GoalSummary.js";
import GoalPlans from "../components/admin/goalPlans/GoalPlans.js";
import GoalEvent from "../components/genzuser/goalEvent/GoalEvent.js";

const useMainRoutes = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route index path="/" element={<LandingPage />} />
				<Route path="/app" element={<Boarding />} />
				<Route path="/verify" element={<VerifyLandingPage />} />
				<Route path="/boarding" element={<Goals />} />
				<Route path="/rewards" element={<GoalOne />} />
				<Route path="/league" element={<GoalTwo />} />
				<Route path="/login" element={<Login />} />
				<Route path="/verification" element={<Verification />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgot_password" element={<ForgotPassword />} />
				<Route path="/default_password" element={<DefaultPassword />} />

				{/* Admin Protected Routes */}
				<Route element={<AdminProtectedLayout />}>
					<Route path="admin" element={<AdminLayout />}>
						<Route path="dashboard" element={<AdminDashboard />} />
						<Route path="users" element={<UsersList />} />
						<Route path="goals" element={<GoalList />} />
						<Route path="settings" element={<Settings />} />
						<Route path="beta-user" element={<BetaUsers />} />
						<Route path="goal-plans" element={<GoalPlans />} />
					</Route>
				</Route>

				{/* User Protected Routes */}
				<Route element={<UserProtectedLayout />}>
					<Route path="genz">
						<Route path="dasbhboard" element={<DreamDashboard />} />
						<Route path="welcome" element={<WelcomeUser />} />
						<Route path="details" element={<GoalDetails />} />
						<Route path="complete" element={<Complete />} />
						<Route path="create-goal" element={<CreateGoal />} />
						<Route path="setup-goal" element={<SetupGoal />} />
						<Route path="goal-summary" element={<GoalSummary />} />
						<Route path="user-profile" element={<UserProfile />} />
						<Route path="goal-event" element={<GoalEvent />} />
						<Route
							path="goal-overview"
							element={<GoalOverview />}
						/>
						<Route
							path="goal-progress"
							element={<GoalProgress />}
						/>
					</Route>
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default useMainRoutes;
