// Chakra Imports
import React, { useState, useRef } from "react";
import backIcon from "../../../images/common/back.png";
import divider from "../../../images/common/divider.png";
import { useNavigate, useLocation } from "react-router-dom";
import googleIcon from "../../../images/common/googleIcon.png";
import facebookIcon from "../../../images/common/facebookIcon.png";
import {
	Img,
	Box,
	Flex,
	Text,
	Button,
	Heading,
	Input,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";

// Component Import
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../constants/color";

// Api Imports
import {
	regenerateEmailCode,
	verifyUserByCode,
} from "../../../services/sharedServices";

function Verification() {
	const toast = useToast();
	const { state } = useLocation();
	const val1Ref = useRef(null);
	const val2Ref = useRef(null);
	const val3Ref = useRef(null);
	const val4Ref = useRef(null);
	const email = state?.emailId;
	const navigate = useNavigate();
	const [val1, setVal1] = useState();
	const [val2, setVal2] = useState();
	const [val3, setVal3] = useState();
	const [val4, setVal4] = useState();
	const [loading, setLoading] = useState(false);

	//Todo: Landscape Settings
	const orientation = useBreakpointValue({
		base: "portrait",
		md: "landscape",
	});

	// Todo: Resend Verification Code
	const resendVerificationCode = () => {
		if (email) {
			setLoading(true);
			let obj = {
				email: email,
			};
			regenerateEmailCode(obj)
				.then(res => {
					setLoading(false);
					if (res?.result) {
						toast(toastFunctionToaster(res?.message, "success"));
					} else {
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					setLoading(false);
					if (err) {
						toast(toastFunctionToaster(err?.message, "success"));
					}
				});
		}
	};

	// Todo: Verify User Code
	const verifyUserCode = () => {
		if (val1 && val2 && val3 && val4) {
			let obj = {
				email: email,
				email_code: `${val1}${val2}${val3}${val4}`,
			};
			verifyUserByCode(obj)
				.then(res => {
					if (res?.result) {
						toast(toastFunctionToaster(res?.message, "success"));
						navigate("/login");
					} else {
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					if (err) {
						toast(toastFunctionToaster(err?.message, "error"));
					}
				});
		}
	};

	return (
		<Box
			bg={syThemeBgBlueColor}
			h={{
				sm: orientation === "portrait" ? "auto" : "auto",
				base: orientation === "portrait" ? "100vh" : "auto",
				md: orientation === "portrait" ? "100vh" : "auto",
				lg: orientation === "portrait" ? "100vh" : "auto",
				xl: orientation === "portrait" ? "100vh" : "auto",
			}}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			mx="auto"
		>
			<Box>
				<Img
					src={backIcon}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</Box>

			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"600"} fontSize={"24px"} color={syWhite}>
					Verify you access code!
				</Heading>
				<Text
					pt="1rem"
					fontWeight={"500"}
					fontSize={"14px"}
					color={syWhite}
				>
					<Text as="span" fontSize={"16px"} fontWeight={"500"} mt="2">
						Hi, {email}
					</Text>{" "}
					<Text>
						Verify you access code send on your email Id or click on
						resend code to generate new code!
					</Text>
				</Text>
			</Box>
			<Box pt="2rem">
				<Flex justifyContent={"space-between"}>
					<Input
						ref={val1Ref}
						value={val1}
						onChange={e => {
							if (/^\d?$/.test(e.target.value)) {
								setVal1(e.target.value);
								if (e.target.value >= 0) {
									val2Ref.current.focus();
								}
							}
						}}
						mr="2"
						bg={syWhite}
						type="number"
						textAlign={"center"}
						height={"60px"}
						fontWeight={"bold"}
						border={"none"}
						_active={{ boxShadow: "none", borderColor: syWhite }}
						maxLength="1"
						min="0"
						max="9"
					></Input>
					<Input
						ref={val2Ref}
						value={val2}
						onChange={e => {
							if (/^\d?$/.test(e.target.value)) {
								setVal2(e.target.value);
								if (e.target.value >= 0) {
									val3Ref.current.focus();
								}
							}
						}}
						mr="2"
						bg={syWhite}
						type="number"
						textAlign={"center"}
						height={"60px"}
						fontWeight={"bold"}
						border={"none"}
						_active={{ boxShadow: "none", borderColor: syWhite }}
						maxLength="1"
						min="0"
						max="9"
					></Input>
					<Input
						ref={val3Ref}
						value={val3}
						onChange={e => {
							if (/^\d?$/.test(e.target.value)) {
								setVal3(e.target.value);
								if (e.target.value >= 0) {
									val4Ref.current.focus();
								}
							}
						}}
						mr="2"
						bg={syWhite}
						type="number"
						textAlign={"center"}
						height={"60px"}
						fontWeight={"bold"}
						border={"none"}
						_active={{ boxShadow: "none", borderColor: syWhite }}
						maxLength="1"
						min="0"
						max="9"
					></Input>
					<Input
						ref={val4Ref}
						value={val4}
						onChange={e => {
							if (/^\d?$/.test(e.target.value)) {
								setVal4(e.target.value);
							}
						}}
						bg={syWhite}
						type="number"
						textAlign={"center"}
						height={"60px"}
						fontWeight={"bold"}
						border={"none"}
						_active={{ boxShadow: "none", borderColor: syWhite }}
						maxLength="1"
						min="0"
						max="9"
					></Input>
				</Flex>
				<Flex
					justifyContent={"flex-end"}
					onClick={() => {
						resendVerificationCode();
					}}
					mt="1rem"
					textAlign={"center"}
					color={syWhite}
					textDecoration={"underline"}
				>
					Regenerate Code
				</Flex>

				<Box mt="5rem" textAlign={"center"}>
					<Button
						w="40%"
						bg={syThemeBgYellowColor}
						color={syThemeBgBlueColor}
						_hover={{ bg: syBlack }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							verifyUserCode();
						}}
						isDisabled={val1 && val2 && val3 && val4 ? false : true}
						isLoading={loading ? true : false}
					>
						Verify Code
					</Button>
					<Text
						mt="1rem"
						textAlign={"center"}
						color={syWhite}
						textDecoration={"underline"}
						onClick={() => {
							navigate("/login");
						}}
					>
						Login
					</Text>
				</Box>
				<Box mt="3rem">
					<Img src={divider} mx="auto" />
				</Box>
				<Flex justifyContent={"center"} mt="3rem">
					<Img src={googleIcon} mr="3" />
					<Img src={facebookIcon} mr="3" />
				</Flex>
			</Box>
		</Box>
	);
}

export default Verification;
