// Chakra Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillLinkedin } from "react-icons/ai";
import { Typewriter } from "react-simple-typewriter";
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Heading,
	Image,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import bgGiff from "../../images/landingPage/bg-video.gif";
import footerLogo from "../../images/landingPage/footerLogo.png";
import { toastFunctionToaster } from "../../utils/toasterFunction";
import { syBlack, syWhite, syTitle, syGrey } from "../../constants/color";

// Api Services
import { addBetaRequest } from "../../services/sharedServices";

function LandingPage() {
	const toast = useToast();
	const navigate = useNavigate();
	const [emailId, setEmailId] = useState("");
	const [loading, setLoading] = useState(false);
	const [emailError, setEmailError] = useState("");

	// Todo:Validate Email
	const validateEmail = () => {
		let formIsValid = true;
		let emailPattern = new RegExp(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		);
		if (!emailId) {
			formIsValid = false;
			setEmailError("*Email is required.");
		} else if (!emailPattern.test(emailId)) {
			formIsValid = false;
			setEmailError("*Please enter valid email address.");
		} else {
			setEmailError();
		}
		return formIsValid;
	};

	const storeData = () => {
		if (validateEmail()) {
			setLoading(true);
			let obj = {
				user_email: emailId,
				first_name: "",
				last_name: "",
			};
			addBetaRequest(obj)
				.then(res => {
					setLoading(false);
					if (res?.result) {
						toast(toastFunctionToaster(res?.message, "success"));
						navigate("/verify", { state: { emailId: emailId } });
					} else {
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					setLoading(false);
					if (err) {
						toast(toastFunctionToaster(err?.message, "error"));
					}
				});
		}
	};

	return (
		<Box>
			<Box
				backgroundImage={bgGiff}
				bgSize={"cover"}
				h={{
					base: "auto",
					md: "95vh",
					lg: "95vh",
					sm: "auto",
				}}
			>
				<Flex
					w="100%"
					h="100%"
					flexWrap={"wrap"}
					textAlign={{
						base: "center",
						md: "start",
						lg: "start",
						sm: "center",
					}}
					pt={{
						base: "4rem",
						md: "0px",
						lg: "0px",
						sm: "4rem",
					}}
				>
					<Flex
						w={{ base: "100%", md: "50%", lg: "50%", sm: "100%" }}
						pl={{ base: "0", md: "4rem", lg: "4rem", sm: "0" }}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Box
							w={{
								base: "90%",
								md: "80%",
								lg: "80%",
								sm: "90%",
							}}
						>
							<Heading
								mb="1.5rem"
								fontSize={{
									base: "2.5rem",
									md: "3.5rem",
									lg: "3.5rem",
									sm: "2.5rem",
								}}
								fontWeight={"600"}
								color={syTitle}
							>
								Syval
							</Heading>
							<Heading
								className="typewriter-text"
								mb="1rem"
								fontSize={{
									base: "2.5rem !important",
									md: "3.5rem !important",
									lg: "3.5rem !important",
									xl: "3rem !important",
									sm: "2.5rem !important",
								}}
							>
								<Typewriter
									words={[
										"Smart Spendings",
										"Better Savings",
										"Together",
									]}
									loop={0}
									cursor
									cursorStyle="."
									typeSpeed={70}
									deleteSpeed={50}
									delaySpeed={1000}
								/>
							</Heading>
							<Text
								color={syTitle}
								fontWeight={"400"}
								pb="2"
								fontSize={"16px"}
							>
								Build Financial Habits Easily with Syval
							</Text>
							<Flex
								mt="4rem"
								alignItems={"center"}
								flexWrap={{
									base: "wrap",
									md: "wrap",
									lg: "wrap",
									xl: "noWrap",
									sm: "wrap",
								}}
							>
								<Input
									w={{
										base: "100%",
										md: "100%",
										lg: "100%",
										xl: "50%",
										sm: "100%",
									}}
									mb={{
										base: "2rem",
										md: "2rem",
										lg: "2rem",
										xl: "0px",
										sm: "2rem",
									}}
									h="60px"
									bg={syWhite}
									borderWidth={"1px"}
									borderColor={syGrey}
									value={emailId}
									onChange={e => {
										setEmailId(e.target.value);
									}}
									placeholder="Your email address"
								></Input>
								<Button
									ml="2"
									w={{
										base: "100%",
										md: "100%",
										lg: "100%",
										xl: "auto",
										sm: "100%",
									}}
									borderRadius={"50px"}
									size={{
										base: "md",
										md: "lg",
										lg: "lg",
										sm: "md",
									}}
									bg={syBlack}
									color={syWhite}
									fontWeight={"normal"}
									_hover={{ bg: syBlack }}
									onClick={() => {
										storeData();
									}}
									isDisabled={loading ? true : false}
									isLoading={loading ? true : false}
								>
									Sign up for Beta Testing
								</Button>
							</Flex>
							<Text color="red" mt="2" fontWeight={"600"}>
								{emailError}
							</Text>
						</Box>
					</Flex>
					<Flex
						w={{ base: "100%", md: "50%", lg: "50%", sm: "100%" }}
						alignItems={"center"}
						justifyContent={"center"}
						mt={{
							base: "4rem",
							md: "none",
							lg: "none",
							sm: "4rem",
						}}
					>
						<Image
							h="100%"
							w={{
								base: "90%",
								md: "70%",
								lg: "70%",
								sm: "90%",
							}}
							src={
								"https://assets.softr-files.com/applications/b935a0b4-9f41-4bb2-8938-0b0607c93421/assets/8ab27ee8-0ee3-4e52-b17e-e3d9fce13764.svg"
							}
						/>
					</Flex>
				</Flex>
			</Box>
			<Box w="90%" mx="auto">
				<Box borderBottom={"1px"} borderBottomColor={syGrey} py="5">
					<Image
						w={{
							base: "20%",
							md: "5%",
							lg: "5%",
							sm: "20%",
						}}
						mx={{
							base: "auto",
							md: "0",
							lg: "0",
							sm: "auto",
						}}
						src={footerLogo}
					/>
				</Box>
				<Flex
					py="5"
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Heading
						fontWeight={"normal"}
						fontSize={{
							base: "14px",
							md: "20px",
							lg: "20px",
							sm: "14px",
						}}
					>
						© 2024 Syval, Inc. All rights reserved.
					</Heading>
					<Flex>
						<AiFillLinkedin />
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
}

export default LandingPage;
