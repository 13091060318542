// Chakra Imports
import { BiDollar } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
	Img,
	Box,
	Flex,
	Text,
	Icon,
	Slider,
	Heading,
	useToast,
	InputGroup,
	Input,
	SliderTrack,
	SliderThumb,
	SliderMark,
	SliderFilledTrack,
	InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CommonWidget from "../../common/CommonWidget";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import { FaChevronRight, FaCaretDown } from "react-icons/fa";
import eventStar from "../../../images/genz/goal/event_star.png";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syGrey,
	syThemeBgGreenColor,
} from "../../../constants/color";

// Api Imports
import { createGoalEvent } from "../../../services/goalServices";

function GoalEvent() {
	const toast = useToast();
	const { state } = useLocation();
	const [sliderValue, setSliderValue] = useState(5);
	const [saveSliderValue, setSaveSliderValue] = useState(1);
	const createdGoalDetails = state?.data;
	const goalPlanningId = state?.goalPlanningId;
	const goalHastag = createdGoalDetails?.goal_tags?.length
		? createdGoalDetails?.goal_tags.split(",")
		: [];
	const todayDate = new Date();
	const [date, setDate] = useState(new Date());
	const [goalAmount, setGoalAmount] = useState(50);
	const [saveAmount, setSavedAmount] = useState(null);
	const [goalDays, setGoalDays] = useState(0);

	const onChange = date => {
		setDate(date);
	};

	// Todo:Render Hashtag
	const renderHashTag = () => {
		if (goalHastag?.length) {
			return goalHastag.map((data, index) => {
				return (
					<Text
						key={data + index}
						bg={syBlack}
						mr="3"
						mt="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						{data}
					</Text>
				);
			});
		}
	};

	useEffect(() => {
		calculateAmount();
	}, [date]);

	const calculateAmount = () => {
		if (date <= todayDate) {
			return 0;
		}
		const weeks = calculateWeeks(todayDate, date);
		setSavedAmount((goalAmount / weeks).toFixed(2));
	};

	const calculateWeeks = (start, end) => {
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
		const timeDifference = end.getTime() - start.getTime();
		const totalDays = Math.ceil(timeDifference / millisecondsPerDay);
		setGoalDays(totalDays);
		return Math.ceil(timeDifference / millisecondsPerWeek); // Get number of full weeks
	};

	useEffect(() => {
		if (goalDays?.length > 0) {
			addDaysToDate();
		}
	}, [goalDays]);

	// Function to add input days to current date
	const addDaysToDate = () => {
		const currentDate = new Date(); // Get current date
		const newDate = new Date(currentDate.getTime()); // Clone current date to avoid modification
		// Add goalDays to the cloned date
		newDate.setDate(newDate.getDate() + parseInt(goalDays));
		setDate(newDate); // Update the date state
	};

	const handleSlide = value => {
		setSaveSliderValue(value);
		if (value === 100) {
			saveEvent();
		}
	};

	const saveEvent = () => {
		let obj = {
			goal_uuid: createdGoalDetails?.goal_type_uuid,
			goal_planning_uuid: goalPlanningId,
			goal_amount: goalAmount,
			goal_amount_currency: createdGoalDetails?.goal_currency,
			event_source: "self",
			event_signal: null,
			event_security: null,
			event_signature: null,
			event_source_details: "Paid by Self",
		};
		createGoalEvent(obj)
			.then(res => {
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	return (
		<Box
			bg={syGrey}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box bg={syThemeBgYellowColor} my="1rem" borderRadius={"10px"}>
					<Heading
						fontWeight={"900"}
						textAlign={"center"}
						py="1.5rem"
						fontSize={"26px"}
					>
						{createdGoalDetails?.goal_name}
					</Heading>
				</Box>
				{goalHastag?.length ? (
					<Flex my="1rem" borderRadius={"10px"} flexWrap={"wrap"}>
						{renderHashTag()}
					</Flex>
				) : null}

				<Box
					bg={syWhite}
					textAlign={"center"}
					px="10px"
					py="20px"
					borderRadius={"10px"}
					boxShadow={"md"}
				>
					<Text fontWeight={"600"} fontSize={"20px"} mb="3">
						Total Savings Goal
					</Text>
					<Text
						fontWeight={"600"}
						fontSize={"20px"}
						color={syThemeBgBlueColor}
					>
						{" "}
						${createdGoalDetails?.goal_amount}{" "}
					</Text>
				</Box>

				<Box
					bg={syWhite}
					textAlign={"center"}
					px="10px"
					py="20px"
					mt="3"
					borderRadius={"10px"}
				>
					<Text
						textAlign={"center"}
						fontWeight={"600"}
						fontSize={"20px"}
						mb="1rem"
					>
						Rewards
					</Text>
					<Flex mt="3">
						<Box w="50%" textAlign={"center"}>
							<Img src={eventStar} mx="auto" />
							<Text fontWeight={"600"} color={syThemeBgBlueColor}>
								10x
							</Text>
							<Text fontWeight={"600"}>Syval Stars</Text>
							<Text>Per Save</Text>
						</Box>
						<Box w="50%">
							<Box position={"relative"} mr="1rem">
								<Img
									w="80px"
									h="80px"
									mx="auto"
									src={standImg}
								></Img>
								<Box
									position={"absolute"}
									top="30px"
									left={"38%"}
								>
									<Img w="50%" src={lockImg}></Img>
								</Box>
							</Box>
							<Text fontWeight={"600"}>I love concert</Text>
							<Text fontWeight={"600"}>Badge</Text>
						</Box>
					</Flex>
					<Text
						textAlign={"center"}
						fontWeight={"600"}
						textDecoration={"underline"}
						mt="1rem"
					>
						Edit
					</Text>
				</Box>

				<Box
					bg={syThemeBgBlueColor}
					textAlign={"center"}
					px="10px"
					py="20px"
					mt="3"
					borderRadius={"10px"}
				>
					<Text color={syWhite} fontSize={"20px"} fontWeight={"600"}>
						Save Now
					</Text>
					<Flex alignItems={"center"} justifyContent={"center"}>
						<Text
							color={syThemeBgGreenColor}
							fontWeight={"600"}
							fontSize={"20px"}
						>
							{" "}
							$250
						</Text>{" "}
						<Text
							color={"#fff"}
							ml="3"
							fontWeight={"600"}
							fontSize={"20px"}
						>
							Per Week
						</Text>
						<Box>
							<FaCaretDown
								color={"#fff"}
								ml="3"
								fontWeight={"600"}
								fontSize={"20px"}
							/>
						</Box>
					</Flex>
					<InputGroup
						border="none"
						bg={syWhite}
						borderRadius={"10px"}
						h="50px"
						my="1rem"
					>
						<InputLeftElement pointerEvents="none" top="5px">
							<BiDollar />
						</InputLeftElement>
						<Input
							type="number"
							fontWeight={"bold"}
							color={syBlack}
							border="0"
							_hover={{ boxShadow: "none" }}
							textAlign={"center"}
							h="50px"
							value={goalAmount}
							onChange={e => {
								setGoalAmount(e.target.value);
							}}
						/>
					</InputGroup>
					<Box
						p={6}
						w="100%"
						textAlign="center"
						position={"relative"}
					>
						<Slider
							aria-label="custom-slide-to-save"
							value={saveSliderValue}
							onChange={handleSlide}
							min={1}
							max={100}
							focusThumbOnChange={false}
							_placeholder={"Slide to Save"}
						>
							{/* Custom Slider Track */}
							<SliderTrack
								bgGradient="linear(90deg, #5F67EC 0%, #DAF66F 100%)"
								borderRadius="full"
								h="40px"
								boxShadow="lg"
							>
								<SliderFilledTrack bg="transparent" />
							</SliderTrack>

							{/* Custom Slider Thumb */}
							<SliderThumb
								boxSize={10}
								bg="white"
								boxShadow="lg"
								_focus={{ boxShadow: "outline" }}
							>
								<Icon as={FaChevronRight} w={5} h={5} />
							</SliderThumb>
						</Slider>
						{/* {saveSliderValue > 80 ? null : ( */}
						<Text
							color={syWhite}
							position={"absolute"}
							top="35%"
							left={"40%"}
							opacity={1 - saveSliderValue / 100}
							transition="opacity 0.2s ease-out"
						>
							Slide To Save
						</Text>
						{/* )} */}
					</Box>
				</Box>

				<Box
					bg={syWhite}
					textAlign={"center"}
					px="10px"
					py="20px"
					mt="3"
					borderRadius={"10px"}
				>
					<Text
						textAlign={"center"}
						fontWeight={"600"}
						fontSize={"20px"}
					>
						My Progress
					</Text>

					<Text
						color={syThemeBgBlueColor}
						textAlign={"center"}
						fontWeight={"600"}
						fontSize={"20px"}
						my="3"
					>
						$500
					</Text>
					<Slider
						id="slider"
						defaultValue={5}
						min={0}
						max={100}
						colorScheme="blue"
						onChange={v => setSliderValue(v)}
						my="1rem"
						w="95%"
					>
						<SliderMark value={0} mt="2" ml="0" fontSize="sm">
							0
						</SliderMark>
						<SliderMark value={25} mt="2" ml="-2.5" fontSize="sm">
							$1000
						</SliderMark>
						<SliderMark value={50} mt="2" ml="1" fontSize="sm">
							$1500
						</SliderMark>
						<SliderMark value={100} mt="2" ml="-8" fontSize="sm">
							$2000
						</SliderMark>
						<SliderTrack h="15px" borderRadius={"10px"}>
							<SliderFilledTrack />
						</SliderTrack>

						<SliderThumb />
					</Slider>
					<Flex justifyContent={"center"} mt="1rem">
						<Img src={eventStar} />
						<Text
							color={syThemeBgBlueColor}
							fontSize={"16px"}
							pt="20px"
						>
							x20
						</Text>
					</Flex>
				</Box>

				<Box
					p="4"
					bg={syWhite}
					w="100%"
					mt="1rem"
					mb="4rem"
					borderRadius={"10px"}
				>
					<Text fontWeight={"600"} textAlign={"center"} mb="1rem">
						My Savings History
					</Text>
					<Calendar
						onChange={onChange}
						value={date}
						minDate={new Date()}
					/>
				</Box>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default GoalEvent;
