// Chakra Imports
import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Img, Box, Flex, Button, Heading } from "@chakra-ui/react";

// Component Imports
import CommonHeader from "../common/CommonHeader";
import completePopper from "../../images/genz/completePopper.png";
import useResponsiveHeight from "../../customHook/ResponsiveHeight";
import {
	syBlack,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../constants/color";

function Complete() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const height = useResponsiveHeight;
	const editGoalFlag = state?.editGoal;

	const navigateToEditGoal = () => {
		navigate("/genz/details", { state: { goalFlag: editGoalFlag } });
	};

	return (
		<Box
			h={height}
			mx="auto"
			overflowX={"hidden"}
			bg={syThemeBgYellowColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
		>
			<Box p="30px">
				<CommonHeader />
				{editGoalFlag ? (
					<Box
						position={"absolute"}
						fontSize={"25px"}
						top={"30px"}
						right={"65px"}
						onClick={() => {
							navigateToEditGoal();
						}}
					>
						<FaRegEdit />
					</Box>
				) : null}
				<Flex
					alignContent={"center"}
					justifyContent={"center"}
					flexWrap={"wrap"}
					textAlign={"center"}
					mt="4rem"
				>
					<Heading
						w="100%"
						color={syBlack}
						fontSize={"22px"}
						mb="3"
						textAlign={"center"}
					>
						Awesome
					</Heading>
					<Heading
						fontSize={"22px"}
						fontWeight={"700"}
						color={syBlack}
					>
						Time to set up your goal!!
					</Heading>
					<Heading
						fontSize={"16px"}
						fontWeight={"700"}
						color={syBlack}
						mt="3rem"
					>
						Create a saving plan and get rich with Syval!
					</Heading>
				</Flex>
				<Img
					src={completePopper}
					h="100%"
					w="90%"
					ml="4rem"
					mt="2rem"
					objectFit={"none"}
				/>
				<Box mt="4rem" textAlign={"center"}>
					<Button
						borderRadius={"50px"}
						size="lg"
						bg={syThemeBgBlueColor}
						color={syWhite}
						w="80%"
						onClick={() => {
							navigate("/genz/create-goal");
						}}
					>
						Let's get Started
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default Complete;
