// Chakra Imports
import React from "react";
import { Img, Box, Button, Heading } from "@chakra-ui/react";

// Component Imports
import { useNavigate } from "react-router-dom";
import backIcon from "../../../images/common/back.png";
import goalBadge from "../../../images/boarding/goal_badge.png";
import goalProgress from "../../../images/boarding/goal_progress.png";
import goalMiniRoom from "../../../images/boarding/goal_mini_room.png";
import goalPaginationOne from "../../../images/boarding/goal_pagination_one.png";
import {
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../constants/color";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight";

function GoalOne() {
	const navigate = useNavigate();
	const height = useResponsiveHeight();
	return (
		<Box
			p="30px"
			mx="auto"
			h={height}
			bg={syThemeBgBlueColor}
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
		>
			<Box>
				<Img
					src={backIcon}
					onClick={() => {
						navigate("/boarding");
					}}
				/>
			</Box>

			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"700"} color={syWhite}>
					Stick to Your Goals
				</Heading>
				<Heading fontWeight={"700"} color={syWhite}>
					Get Rewards
				</Heading>

				<Box pt="3rem" position="relative">
					<Img src={goalProgress} mx="auto" />
				</Box>
				<Box position="relative" mt="-5.5rem" zIndex={2}>
					<Img src={goalMiniRoom} />
				</Box>
				<Box position="relative" mt="-7rem" ml="11rem">
					<Img src={goalBadge} />
				</Box>
				<Box pt="1rem">
					<Img mx="auto" src={goalPaginationOne} />
				</Box>
				<Box pt="1rem" textAlign={"center"}>
					<Button
						w="80%"
						bg={syThemeBgYellowColor}
						color={syThemeBgBlueColor}
						_hover={{ bg: syThemeBgYellowColor }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							navigate("/league");
						}}
					>
						Next
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default GoalOne;
