import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
	Box,
	Flex,
	Heading,
	Img,
	ListItem,
	Text,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import moment from "moment";
import {
	syBlack,
	syThemeBgGreenColor,
	syWhite,
} from "../../../constants/color";
import calenderImg from "../../../images/genz/calender-white.png";

const WeekCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [weekDates, setWeekDates] = useState([]);
	const [toggleCalender, setToggleCalender] = useState(false);

	// Function to get the week dates based on the selected date
	const getWeekDates = date => {
		const start = moment(date).startOf("week"); // Start of the week (Sunday)
		const dates = [];

		// Loop through the week and get each day's date
		for (let i = 0; i < 7; i++) {
			dates.push(moment(start).add(i, "days"));
		}
		return dates;
	};

	// Update the week dates when a new date is selected
	useEffect(() => {
		setWeekDates(getWeekDates(selectedDate));
	}, [selectedDate]);

	const isSelectedDate = date => moment(date).isSame(selectedDate, "day");

	const handleDateClick = date => {
		setSelectedDate(date.toDate()); // Update selectedDate with the clicked date
	};

	const handleCalendarChange = date => {
		setSelectedDate(date); // Update selectedDate with the calendar clicked date
	};
	const selectedMonthYear = moment(selectedDate).format("MMMM YYYY");
	return (
		<VStack align="center">
			<Box borderRadius="lg" w="100%">
				<Flex
					justifyContent={"space-between"}
					alignItems="center"
					my="1rem"
				>
					<Heading as="h3" size="md" mb={4} color={syWhite}>
						{selectedMonthYear}
					</Heading>
					<Img
						src={calenderImg}
						onClick={() => {
							setToggleCalender(!toggleCalender);
						}}
					/>
				</Flex>
				{toggleCalender ? (
					<Calendar
						onChange={handleCalendarChange}
						value={selectedDate}
						w="100%"
					/>
				) : null}
				<Flex justifyContent={"space-between"} mt="1rem">
					{weekDates.map((date, index) => (
						<Box
							key={index}
							p="10px"
							// bg={"#8f95f2"}
							borderRadius={"30px"}
							textAlign={"center"}
							bg={
								isSelectedDate(date)
									? syThemeBgGreenColor
									: "#8f95f2"
							}
							color={isSelectedDate(date) ? syWhite : "inherit"}
							onClick={() => handleDateClick(date)}
						>
							<Text
								color={isSelectedDate(date) ? syBlack : syWhite}
								fontWeight={600}
							>
								{date.format("dd")}
								{/* First letter of the day and current date */}
							</Text>
							<Text color={syWhite}>{date.format("DD")} </Text>
						</Box>
					))}
				</Flex>
			</Box>
		</VStack>
	);
};

export default WeekCalendar;
