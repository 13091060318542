// Chakra Imports
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Img, Box, Flex, Text, Button, Heading } from "@chakra-ui/react";

// Component Imports
import CommonHeader from "../../common/CommonHeader";
import CommonWidget from "../../common/CommonWidget";
import FooterProfile from "../../common/FooterProfile";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import lightImg from "../../../images/genz/goal/lighting.png";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syGrey,
} from "../../../constants/color";

function GoalSummary() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const createdGoalDetails = state?.data;
	const goalDataName = createdGoalDetails?.goal_name;
	const goalHastag = createdGoalDetails?.goal_tags?.length
		? createdGoalDetails?.goal_tags.split(",")
		: [];

	// Todo:Render Hashtag
	const renderHashTag = () => {
		if (goalHastag?.length) {
			return goalHastag.map((data, index) => {
				return (
					<Text
						key={data + index}
						bg={syBlack}
						mr="3"
						mt="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						{data}
					</Text>
				);
			});
		}
	};

	return (
		<Box
			bg={syGrey}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box bg={syThemeBgYellowColor} my="1rem" borderRadius={"10px"}>
					<Heading
						fontWeight={"900"}
						textAlign={"center"}
						py="1.5rem"
						fontSize={"26px"}
					>
						{goalDataName}
					</Heading>
				</Box>

				<Flex
					my="1rem"
					borderRadius={"10px"}
					flexWrap={"wrap"}
					mb="2rem"
				>
					{renderHashTag()}
				</Flex>

				<Box mb="1rem" bg={syWhite}>
					<Box>
						<Img mx="auto" src={lightImg}></Img>
					</Box>

					<Box position={"relative"} pb="2rem">
						<Img mx="auto" src={standImg}></Img>
						<Box position={"absolute"} top="25px" left={"40%"}>
							<Img src={lockImg}></Img>
						</Box>
					</Box>
				</Box>

				<Box
					px="20px"
					py="30px"
					bg={syWhite}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						fontSize={"24px"}
						fontWeight={"700"}
						color={syThemeBgBlueColor}
					>
						$ {createdGoalDetails?.goal_target_amount}
					</Heading>
					<Heading
						fontSize={"18px"}
						fontWeight={"700"}
						mt="1rem"
						color={syBlack}
					>
						in
					</Heading>
					<Heading
						fontSize={"24px"}
						fontWeight={"700"}
						mt="1rem"
						color={syThemeBgBlueColor}
					>
						{createdGoalDetails?.goal_duration_days} days
					</Heading>
				</Box>
				<Box mt="0.5rem" borderRadius={"8px"} textAlign={"center"}>
					<Heading
						textAlign={"center"}
						fontSize={"20px"}
						fontWeight={"700"}
						color={syBlack}
					>
						=
					</Heading>
				</Box>
				<Box
					mt="0.5rem"
					px="20px"
					py="30px"
					bg={syThemeBgBlueColor}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						textAlign={"center"}
						fontSize={"20px"}
						fontWeight={"700"}
						color={syThemeBgYellowColor}
					>
						$ {createdGoalDetails?.goal_save_amount}
						<Text
							as="span"
							fontSize={"16px"}
							mx="2"
							color={syWhite}
						>
							Every
						</Text>{" "}
						Week
					</Heading>
					<Heading
						textAlign={"center"}
						fontSize={"16px"}
						fontWeight={"700"}
						color={syWhite}
						mt="2rem"
					>
						Edit
					</Heading>
				</Box>
				<Box textAlign={"center"} mt="2rem" mb="4rem">
					<Button
						borderRadius={"50px"}
						size="lg"
						w="80%"
						bg={syThemeBgYellowColor}
						color={syBlack}
						boxShadow={"xs"}
						onClick={() => {
							navigate("/genz/dasbhboard");
						}}
					>
						Let's do it
					</Button>
				</Box>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default GoalSummary;
