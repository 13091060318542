// Chakra Imports
import React from "react";
import { useSelector } from "react-redux";
import { Img, Box, Flex, Text } from "@chakra-ui/react";

// Component Imports
import chip from "../../images/genz/chip.png";
import rating from "../../images/genz/rating.png";
import { syThemeBgBlueColor } from "../../constants/color";

function CommonWidget() {
	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { widgetData } = sideMenuStatus;
	return (
		<Box
			w="80px"
			h="75px"
			bg={"#eff0fd"}
			borderRadius={"5px"}
			p="10px"
			px="15px"
		>
			<Flex alignItems={"center"} justifyContent={"space-between"}>
				<Text
					fontSize={"16px"}
					fontWeight={"700"}
					color={syThemeBgBlueColor}
				>
					{widgetData?.stars}
				</Text>
				<Img src={rating} />
			</Flex>
			<Flex alignItems={"center"} justifyContent={"space-between"}>
				<Text
					fontSize={"16px"}
					fontWeight={"700"}
					color={syThemeBgBlueColor}
				>
					{widgetData?.saving}
				</Text>
				<Img src={chip} />
			</Flex>
		</Box>
	);
}

export default CommonWidget;
