// Chakra React Imports
import { useSelector } from "react-redux";
import { MdDashboard } from "react-icons/md";
import { FaUserTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { React, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Text, Icon, Link, Flex } from "@chakra-ui/react";
import {
	AiOutlineDown,
	AiOutlineRight,
	AiOutlineUser,
	AiFillStar,
} from "react-icons/ai";
import { FaTasks } from "react-icons/fa";

// Componemt Imports
import Logout from "../../common/Logout";
import { syBlack, syThemeBgBlueColor } from "../../../constants/color";
import { getGroupId, getUserName } from "../../../utils/localStorageIndex";

function MenuDetails() {
	const name = getUserName();
	const groupId = getGroupId();
	const location = useLocation();
	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { sideMenuFlag } = sideMenuStatus;

	useEffect(() => {
		let newArr = [...sideMenuArr];
		newArr.map(data => {
			if (location.pathname === data?.path) {
				setCurrentSelectedMenu(data?.label);
			}
		});
	}, [location.pathname]);

	const [sideMenuArr, setSideMenuArr] = useState([
		{
			label: "Dashboard",
			icon: MdDashboard,
			group: ["100"],
			isSelected: true,
			path: "/admin/dashboard",
		},
		{
			label: "Beta User",
			icon: FaUserTimes,
			group: ["100"],
			isSelected: true,
			path: "/admin/beta-user",
		},
		{
			label: "Users",
			icon: AiOutlineUser,
			group: ["100"],
			isSelected: true,
			path: "/admin/users",
		},
		{
			label: "Goal",
			icon: AiFillStar,
			group: ["100"],
			isSelected: true,
			path: "/admin/goals",
		},
		{
			label: "Goal Plans",
			icon: FaTasks,
			group: ["100"],
			isSelected: true,
			path: "/admin/goal-plans",
		},
	]);

	const [currentSelectedMenu, setCurrentSelectedMenu] = useState("Dashboard");

	const openDropdownMenu = menuData => {
		let newArr = [...sideMenuArr];
		newArr.map(data => {
			if (data.label === menuData.label) {
				data.isSelected = !data.isSelected;
			}
		});
		setSideMenuArr(newArr);
	};

	const SideMenuUI = sideMenuArr?.map((data, index) => {
		if (data?.group?.includes(groupId)) {
			return (
				<Box key={index}>
					<Flex
						alignItems="center"
						onClick={() => {
							openDropdownMenu(data);
						}}
						cursor="pointer"
						w="100%"
					>
						{data?.subMenu ? (
							data.isSelected === true ? (
								<AiOutlineDown style={{ cursor: "pointer" }} />
							) : (
								<AiOutlineRight style={{ cursor: "pointer" }} />
							)
						) : (
							<Link
								as={RouterLink}
								to={data?.path}
								_focus={{ boxShadow: "none" }}
								style={{ textDecoration: "none" }}
								w="100%"
								onClick={() => {
									setCurrentSelectedMenu(data.label);
								}}
							>
								<Flex
									alignItems="center"
									bg={
										currentSelectedMenu === data.label
											? syBlack
											: null
									}
									p="2"
									borderRadius="5px"
								>
									<Icon
										mx="5px"
										mr="5"
										fontSize={sideMenuFlag ? "25" : "36"}
										fontWeight="600"
										as={data.icon}
										_groupHover={{ color: "white" }}
										color={
											currentSelectedMenu === data.label
												? syThemeBgBlueColor
												: null
										}
									/>
									{sideMenuFlag ? (
										<Text fontSize="14px">
											{data.label}
										</Text>
									) : null}
								</Flex>
							</Link>
						)}

						{/* Main Menu Header text */}
						{sideMenuFlag && data?.subMenu?.length ? (
							<Text fontSize={"20px"}>{data.label}</Text>
						) : null}
					</Flex>
					{data?.subMenu?.length ? (
						<Box>
							{data?.subMenu.map((subMenuData, index) => (
								<Box
									key={index}
									onClick={() => {
										setCurrentSelectedMenu(
											subMenuData.label
										);
									}}
									display={
										data.isSelected === true
											? "block"
											: "none"
									}
								>
									<Link
										as={RouterLink}
										to={subMenuData?.path}
										_focus={{ boxShadow: "none" }}
										style={{ textDecoration: "none" }}
									>
										<Flex
											alignItems="center"
											p="5px"
											// pl={sideMenuFlag ? "45px" : "25px"}
											color={"white"}
											bg={
												currentSelectedMenu ===
												subMenuData.label
													? syBlack
													: null
											}
										>
											<Icon
												mr={sideMenuFlag ? "4" : "0px"}
												fontSize="25px"
												_groupHover={{ color: "white" }}
												as={subMenuData.icon}
											/>
											{/* Main Menu label text */}
											{sideMenuFlag ? (
												<Flex alignItems="center">
													<Text
														textTransform="capitalize"
														fontSize={"18px"}
													>
														{subMenuData.label}
													</Text>
												</Flex>
											) : null}
										</Flex>
									</Link>
								</Box>
							))}
						</Box>
					) : null}
				</Box>
			);
		}
	});

	return (
		<Box m={0} p={0}>
			{SideMenuUI}
			<Flex
				p="10px"
				borderRadius="10px"
				alignItems="center"
				position=" fixed"
				w="15%"
				overflow={"hidden"}
				bottom="10px"
				left="0"
				cursor={"pointer"}
				flexWrap={"wrap"}
			>
				<Text mb="3" w="100%">
					{name}
				</Text>
				<Logout size={"sm"} borderRadius={"5"} ml={"0"} />
			</Flex>
		</Box>
	);
}

export default MenuDetails;
