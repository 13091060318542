// React Chakra Imports
import { BiHide, BiShow } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import {
	Img,
	Box,
	Text,
	Input,
	Flex,
	Button,
	InputGroup,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	InputRightElement,
	useTab,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import bellIcon from "../../../../images/genz/accordion/settings/bell.png";
import inboxIcon from "../../../../images/genz/accordion/settings/inbox.png";
import messageIcon from "../../../../images/genz/accordion/settings/message.png";
import {
	syBlack,
	syWhite,
	syBorderGrey,
	syThemeLabelColor,
	syThemeBgBlueColor,
} from "../../../../constants/color";
import { updatePassword } from "../../../../services/userServices";
import { toastFunctionToaster } from "../../../../utils/toasterFunction";
import { useNavigate } from "react-router-dom";
import { applicationLogout } from "../../../../services/sharedServices";
import {
	clearLocalStorage,
	getEmailId,
} from "../../../../utils/localStorageIndex";

function Settings() {
	const toast = useToast();
	const emailId = getEmailId();
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [loading, isloading] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [dummyPassword, setDummyPassword] = useState("");
	const [reEnterPassword, SetReEnterPassword] = useState("");
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);
	const [dummyPasswordErr, setDummyPasswordErr] = useState("");
	const [newPasswordErr, setNewPasswordErr] = useState("");
	const [reEnterPasswordErr, setReEnterPasswordErr] = useState("");

	// Todo:Validate Form
	const validateFieldForm = () => {
		let formIsValid = true;
		if (!dummyPassword) {
			formIsValid = false;
			setDummyPasswordErr("*Default Password is required.");
		} else {
			setDummyPasswordErr("");
		}

		if (!newPassword) {
			formIsValid = false;
			setNewPasswordErr("*New Password is required.");
		} else {
			setNewPasswordErr("");
		}

		if (!reEnterPassword) {
			formIsValid = false;
			setReEnterPasswordErr("*Re-Enter New Password required.");
		} else if (newPassword !== reEnterPassword) {
			formIsValid = false;
			setReEnterPasswordErr(
				"*New Password and Re-Enter Password do not match."
			);
		} else {
			setReEnterPasswordErr("");
		}

		return formIsValid;
	};

	const changePassword = () => {
		if (validateFieldForm()) {
			isloading(true);
			let obj = {
				current_password: dummyPassword,
				new_password: newPassword,
			};
			updatePassword(obj)
				.then(res => {
					isloading(false);
					if (res.result) {
						systemLogout();
						toast(toastFunctionToaster(res?.message, "success"));
					} else {
						isloading(false);
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					isloading(false);
					if (err)
						toast(toastFunctionToaster(err?.response, "error"));
				});
		}
	};

	const systemLogout = () => {
		isloading(true);
		var apiData = { email: emailId };
		applicationLogout(apiData)
			.then(res => {
				isloading(false);
				if (res.result) {
					toast(toastFunctionToaster(res?.message, "success"));
					navigate("/login");
					clearLocalStorage();
				} else {
					toast(toastFunctionToaster(res?.message, "success"));
				}
			})
			.catch(err => {
				isloading(false);
				toast(toastFunctionToaster(err?.message, "success"));
			});
	};

	return (
		<Box my="4">
			<Text fontSize={"16px"} fontWeight={"600"} color={syBorderGrey}>
				General
			</Text>
			<Accordion allowMultiple mt="5">
				{/* Forgot Password Accordion */}
				<AccordionItem border={"0"} mb="5">
					{({ isExpanded }) => (
						<>
							<AccordionButton _hover={{ bg: "none" }} p="0">
								<Box
									as="span"
									flex="1"
									textAlign="left"
									fontWeight={"bold"}
								>
									Forgot Password
								</Box>
								{isExpanded ? (
									<IoIosArrowDown fontSize="16px" />
								) : (
									<IoIosArrowForward fontSize="16px" />
								)}
							</AccordionButton>
							<AccordionPanel pb={4} px="0">
								<Box>
									<Box
										borderRadius={"5px"}
										position={"relative"}
										mt="1rem"
										borderWidth={"1px"}
										borderColor={syBorderGrey}
										pt="2px"
									>
										<Text
											fontSize={"14px"}
											position={"absolute"}
											zIndex={"2"}
											top="5px"
											fontWeight={600}
											color={syThemeLabelColor}
											pl="15px"
										>
											Default Password
										</Text>
										<InputGroup>
											<Input
												type={
													show ? "text" : "password"
												}
												value={dummyPassword}
												name="dummyPassword"
												onChange={e => {
													setDummyPassword(
														e.target.value
													);
												}}
												border={"none"}
												_active={{
													boxShadow: "none",
													borderColor: syWhite,
												}}
												pt="5px"
												height={"60px"}
											/>
											<InputRightElement width="4.5rem">
												<Button
													mt="15px"
													h="1.75rem"
													size="sm"
													onClick={() => {
														setShow(!show);
													}}
													bg="none"
													_hover={{ bg: "none" }}
												>
													{show ? (
														<BiHide
															size="sm"
															color={syBlack}
														/>
													) : (
														<BiShow
															size="sm"
															color={syBlack}
														/>
													)}
												</Button>
											</InputRightElement>
										</InputGroup>
									</Box>
									<Text color="red" mt="2" fontWeight={"600"}>
										{dummyPasswordErr}
									</Text>
								</Box>
								<Box mt="2">
									<Box
										borderRadius={"5px"}
										position={"relative"}
										mt="1rem"
										borderWidth={"1px"}
										borderColor={syBorderGrey}
										pt="2px"
									>
										<Text
											pl="15px"
											fontSize={"14px"}
											position={"absolute"}
											zIndex={"2"}
											top="5px"
											fontWeight={600}
											color={syThemeLabelColor}
										>
											New Password
										</Text>
										<InputGroup>
											<Input
												type={
													showNewPassword
														? "text"
														: "password"
												}
												value={newPassword}
												name="newPassword"
												onChange={e => {
													setNewPassword(
														e.target.value
													);
												}}
												border={"none"}
												_active={{
													boxShadow: "none",
													borderColor: syWhite,
												}}
												pt="5px"
												height={"60px"}
											/>
											<InputRightElement width="4.5rem">
												<Button
													mt="15px"
													h="1.75rem"
													size="sm"
													onClick={() => {
														setShowNewPassword(
															!showNewPassword
														);
													}}
													bg="none"
													_hover={{ bg: "none" }}
												>
													{showNewPassword ? (
														<BiHide
															size="sm"
															color={syBlack}
														/>
													) : (
														<BiShow
															size="sm"
															color={syBlack}
														/>
													)}
												</Button>
											</InputRightElement>
										</InputGroup>
									</Box>
									<Text color="red" mt="2" fontWeight={"600"}>
										{newPasswordErr}
									</Text>
								</Box>
								<Box mt="2">
									<Box
										borderRadius={"5px"}
										position={"relative"}
										mt="1rem"
										borderWidth={"1px"}
										borderColor={syBorderGrey}
										pt="2px"
									>
										<Text
											pl="15px"
											fontSize={"14px"}
											position={"absolute"}
											zIndex={"2"}
											top="5px"
											fontWeight={600}
											color={syThemeLabelColor}
										>
											Re-Enter New Password
										</Text>
										<InputGroup>
											<Input
												type={
													showRePassword
														? "text"
														: "password"
												}
												value={reEnterPassword}
												name="reEnterPassword"
												onChange={e => {
													SetReEnterPassword(
														e.target.value
													);
												}}
												border={"none"}
												_active={{
													boxShadow: "none",
													borderColor: syWhite,
												}}
												pt="5px"
												height={"60px"}
											/>
											<InputRightElement width="4.5rem">
												<Button
													mt="15px"
													h="1.75rem"
													size="sm"
													onClick={() => {
														setShowRePassword(
															!showRePassword
														);
													}}
													bg="none"
													_hover={{ bg: "none" }}
												>
													{showRePassword ? (
														<BiHide
															size="sm"
															color={syBlack}
														/>
													) : (
														<BiShow
															size="sm"
															color={syBlack}
														/>
													)}
												</Button>
											</InputRightElement>
										</InputGroup>
									</Box>
									<Text color="red" mt="2" fontWeight={"600"}>
										{reEnterPasswordErr}
									</Text>
								</Box>
								<Box textAlign={"right"}>
									<Button
										colorScheme="green"
										size="xs"
										onClick={() => {
											changePassword();
										}}
										isDisabled={loading ? true : false}
										isLoading={loading ? true : false}
									>
										Change Password
									</Button>
								</Box>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
				{/* Notification Accordion */}
				<AccordionItem border={"0"} mb="5">
					{({ isExpanded }) => (
						<>
							<AccordionButton _hover={{ bg: "none" }} p="0">
								<Box
									as="span"
									flex="1"
									textAlign="left"
									fontWeight={"bold"}
								>
									Notifications
								</Box>
								{isExpanded ? (
									<IoIosArrowDown fontSize="16px" />
								) : (
									<IoIosArrowForward fontSize="16px" />
								)}
							</AccordionButton>
							<AccordionPanel pb={4} px="0">
								<Flex
									borderWidth={"1px"}
									borderColor={syBlack}
									borderRadius={"5px"}
									justifyContent={"space-between"}
									alignItems={"center"}
									px="10px"
									py="20px"
								>
									<Text fontWeight={"bold"}>
										Savings Reminder
									</Text>
									<Flex alignItems={"center"}>
										<Img h="20px" mr="2" src={bellIcon} />
										<Img
											h="20px"
											mr="2"
											src={messageIcon}
										/>
										<Img h="15px" src={inboxIcon} />
									</Flex>
								</Flex>
								<Flex
									borderWidth={"1px"}
									borderColor={syBlack}
									borderRadius={"5px"}
									justifyContent={"space-between"}
									alignItems={"center"}
									px="10px"
									py="20px"
									mt="3"
								>
									<Text fontWeight={"bold"}>Reminder</Text>
									<Text
										fontWeight={"bold"}
										color={syThemeBgBlueColor}
									>
										20:00
									</Text>
								</Flex>
								<Flex
									borderWidth={"1px"}
									borderColor={syBlack}
									borderRadius={"5px"}
									justifyContent={"space-between"}
									alignItems={"center"}
									px="10px"
									py="20px"
									mt="3"
								>
									<Text fontWeight={"bold"}>
										Weekly Progress
									</Text>
									<Flex alignItems={"center"}>
										<Img h="20px" mr="2" src={bellIcon} />
										<Img
											h="20px"
											mr="2"
											src={messageIcon}
										/>
										<Img h="15px" src={inboxIcon} />
									</Flex>
								</Flex>
								<Flex
									borderWidth={"1px"}
									borderColor={syBlack}
									borderRadius={"5px"}
									justifyContent={"space-between"}
									alignItems={"center"}
									px="10px"
									py="20px"
									mt="3"
								>
									<Text fontWeight={"bold"}>Leaderboard</Text>
									<Flex alignItems={"center"}>
										<Img h="20px" mr="2" src={bellIcon} />
										<Img
											h="20px"
											mr="2"
											src={messageIcon}
										/>
										<Img h="15px" src={inboxIcon} />
									</Flex>
								</Flex>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
			<Text
				fontSize={"16px"}
				fontWeight={"600"}
				my="4"
				color={syBorderGrey}
			>
				Security
			</Text>
			<Accordion allowMultiple mt="5">
				<AccordionItem border={"0"} mb="5">
					{({ isExpanded }) => (
						<>
							<AccordionButton _hover={{ bg: "none" }} p="0">
								<Box
									as="span"
									flex="1"
									textAlign="left"
									fontWeight={"bold"}
								>
									Privacy Policy
								</Box>
								{isExpanded ? (
									<IoIosArrowDown fontSize="16px" />
								) : (
									<IoIosArrowForward fontSize="16px" />
								)}
							</AccordionButton>
							<AccordionPanel pb={"0"} px="0">
								<Text color={syBorderGrey}>
									Choose what data you share with us
								</Text>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
		</Box>
	);
}

export default Settings;
