// React Chakra Imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Box, Img } from "@chakra-ui/react";

// Component Imports
import { syWhite } from "../../constants/color";
import addImage from "../../images/genz/common-footer/plus.png";
import profileImg from "../../images/genz/common-footer/profile.png";
import calenderImg from "../../images/genz/common-footer/calendar.png";

function FooterProfile({
	position,
	top,
	left,
	right,
	bottom,
	borderRadius,
	userRoute,
}) {
	const navigate = useNavigate();

	const routeToPath = () => {
		navigate(userRoute);
	};
	return (
		<Flex
			position={position}
			bg={"#fff"}
			bottom={bottom}
			w="100%"
			justifyContent={"space-between"}
			py="25px"
			px="60px"
			borderTopLeftRadius={"30px"}
			borderTopRightRadius={"30px"}
		>
			<Box h="30px" cursor={"pointer"} position={"relative"}>
				<Img h="100%" src={calenderImg} />
				<Box
					top="-10px"
					right="-10px"
					h="25px"
					w="25px"
					textAlign="center"
					position="absolute"
					bg="red.500"
					p="1px"
					color={syWhite}
					borderRadius="50px"
					onClick={() => {
						navigate("/genz/dasbhboard");
					}}
				>
					1
				</Box>
			</Box>
			<Box
				h="30px"
				cursor={"pointer"}
				onClick={() => {
					navigate("/genz/create-goal");
				}}
			>
				<Img h="100%" src={addImage} />
			</Box>
			<Box
				h="30px"
				cursor={"pointer"}
				position={"relative"}
				onClick={() => {
					navigate("/genz/user-profile");
				}}
			>
				<Img h="100%" src={profileImg} />
				<Box
					top="-10px"
					right="-10px"
					h="25px"
					w="25px"
					textAlign="center"
					position="absolute"
					bg="red.500"
					p="1px"
					color={syWhite}
					borderRadius="50px"
				>
					1
				</Box>
			</Box>
		</Flex>
	);
}

export default FooterProfile;
