import { FaPlus } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import {
	Switch,
	Flex,
	Text,
	Input,
	Button,
	Box,
	Img,
	Spinner,
	useToast,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports
import AddUpdateGoal from "./AddUpdateGoal";
import ResponsivePagination from "react-responsive-pagination";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syGrey,
	syWhite,
	syThemeBgYellowColor,
	syThemeBgBlueColor,
} from "../../../constants/color";

// Api Imports
import UploadGoalIcons from "./UploadGoalIcons";
import {
	handleGoalStatus,
	fetchGoalsList,
} from "../../../services/goalServices";

function GoalList() {
	const toast = useToast();
	const btnRef = React.useRef();
	const [loading, isloading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [goalEnable, setGoalEnable] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [goalDetails, setGoalDetails] = useState(null);
	const [iconUploadFlag, setIconUploadFlag] = useState(true);
	const [selectedGoalLabel, setSeletectedGoalLabel] = useState("");
	const [switchDisableFlag, setSwitchDisableFlag] = useState(false);

	useEffect(() => {
		fetchGoalDetails();
	}, [goalEnable, currentPage]); // Update fetch when page changes

	const fetchGoalDetails = () => {
		isloading(true);
		let objBody = {
			enabled: goalEnable,
			filter_key: "goal_name",
			filter_value: "string",
			records_per_page: 10,
			page_id: currentPage,
		};
		fetchGoalsList(objBody)
			.then(res => {
				isloading(false);
				if (res.data.length > 0) {
					setTotalPages(res.data[0].total_pages);
					setTableData(res.data[0].records);
				} else {
					setTotalPages(0);
					setTableData([]);
				}
			})
			.catch(err => {
				isloading(false);
			});
	};

	const handleSearch = e => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);
	};

	const filteredData = tableData.filter(data => {
		// Implement filtering logic here
		return (
			data?.goal_name?.toLowerCase().includes(searchTerm) ||
			data?.goal_type?.toLowerCase().includes(searchTerm) ||
			data?.goal_rewards?.toLowerCase().includes(searchTerm)
		);
	});

	// 	// Todo:Handle After Modal Close
	const handleCloseData = () => {
		onClose();
		setGoalDetails(null);
		setIconUploadFlag(false);
		setSeletectedGoalLabel("");
	};

	// Todo:Update Goal Status
	const updateGoalStatus = data => {
		setSwitchDisableFlag(true);
		let obj = {
			goal_uuid: data?.goal_uuid,
		};
		handleGoalStatus(obj)
			.then(res => {
				setSwitchDisableFlag(false);
				fetchGoalDetails();
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				setSwitchDisableFlag(false);
				toast(
					toastFunctionToaster("Something Went Wrong!!!!", "error")
				);
			});
	};

	function renderGoalDetails() {
		if (filteredData && filteredData.length > 0) {
			var goalDataResult = filteredData.map((data, index) => (
				<Flex
					p="15px"
					pl="0px"
					key={data?.uuid}
					color={syWhite}
					borderTopWidth={index === 0 ? "0.5px" : "0pc"}
					borderTopColor={syGrey}
					borderBottomWidth={"0.5px"}
					borderBottomColor={syGrey}
					cursor={"pointer"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Flex
						flexWrap={"wrap"}
						w="80%"
						borderLeftWidth={"20px"}
						pl={5}
						borderLeftColor={
							data?.goal_enable ? "green.300" : "red.200"
						}
					>
						<Box
							w="350px"
							overflow={"hidden"}
							borderRightWidth={"1px"}
							pr="10px"
						>
							<Flex>
								<Text mb="5px" fontSize={"14px"} width="150px">
									Goal Name :{" "}
								</Text>
								<Text
									fontWeight={"600"}
									width={"250px"}
									textWrap="noWrap"
									textOverflow={"ellipsis"}
									whiteSpace={"noWrap"}
									ml="2"
									overflow={"hidden"}
								>
									{data?.goal_name
										? data?.goal_name
										: "Not Available"}
								</Text>
							</Flex>
							<Flex>
								<Text mb="5px" fontSize={"14px"} width="150px">
									Goal Type :{" "}
								</Text>
								<Text
									fontWeight={"600"}
									width={"250px"}
									textWrap="noWrap"
									textOverflow={"ellipsis"}
									whiteSpace={"noWrap"}
									ml="2"
									overflow={"hidden"}
								>
									{data?.goal_type
										? data?.goal_type
										: "Not Available"}
								</Text>
							</Flex>
							<Flex>
								<Text mb="5px" fontSize={"14px"} width="150px">
									Goal Rewards :{" "}
								</Text>
								<Text
									fontWeight={"600"}
									width={"250px"}
									textWrap="noWrap"
									textOverflow={"ellipsis"}
									whiteSpace={"noWrap"}
									ml="2"
									overflow={"hidden"}
								>
									{data?.goal_rewards
										? data?.goal_rewards
										: "Not Available"}
								</Text>
							</Flex>
						</Box>

						<Box
							mx="10px"
							px="10px"
							w="150px"
							textAlign={"center"}
							borderRightWidth={"1px"}
						>
							<Text fontWeight={"600"} mb="1">
								Goal Icon
							</Text>
							{data?.goal_icon ? (
								<Img
									h="60px"
									w="60px"
									mx="auto"
									objectFit={"cover"}
									src={data?.goal_icon}
									borderRadius={"50px"}
								/>
							) : (
								<Text>Not Available</Text>
							)}
						</Box>
						<Box
							mr="10px"
							pr="10px"
							w="150px"
							textAlign={"center"}
							borderRightWidth={"1px"}
						>
							<Text fontWeight={"600"} mb="1">
								Reward Icon
							</Text>
							{data?.goal_reward_icon ? (
								<Img
									h="60px"
									w="60px"
									mx="auto"
									objectFit={"cover"}
									borderRadius={"50px"}
									src={data?.goal_reward_icon}
								/>
							) : (
								<Text>Not Available</Text>
							)}
						</Box>
					</Flex>
					<Box>
						{switchDisableFlag ? (
							<Spinner my="2" mt="2" size="sm" />
						) : (
							<Flex alignItems={"center"}>
								{data?.goal_enable ? (
									<>
										<Button
											colorScheme="yellow"
											size="xs"
											mr="2"
											onClick={() => {
												onOpen();
												setGoalDetails(data);
												setIconUploadFlag(true);
												setSeletectedGoalLabel("icon");
											}}
										>
											Upload Goal Icon
										</Button>
										<Button
											colorScheme="yellow"
											size="xs"
											mr="2"
											onClick={() => {
												onOpen();
												setGoalDetails(data);
												setIconUploadFlag(true);
												setSeletectedGoalLabel(
													"reward"
												);
											}}
										>
											Upload Reward Icon
										</Button>
										<AiFillEdit
											fontSize={"20"}
											color={syThemeBgBlueColor}
											onClick={() => {
												onOpen();
												setGoalDetails(data);
												setIconUploadFlag(false);
											}}
										/>
									</>
								) : null}
								<Switch
									colorScheme={
										data?.goal_enable ? "green" : "red"
									}
									ml="2"
									isChecked={
										data?.goal_enable
											? data?.goal_enable
											: !data?.goal_enable
									}
									isDisabled={switchDisableFlag}
									onChange={() => {
										updateGoalStatus(data);
									}}
								/>
							</Flex>
						)}
					</Box>
				</Flex>
			));
			return goalDataResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Goal Data Found!!"}
					</Text>
				</Box>
			);
		}
	}

	const refreshGoalData = value => {
		if (value === "true") {
			fetchGoalDetails();
			handleCloseData();
		}
	};

	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" color={syWhite} />
				</Flex>
			) : (
				<Box>
					<Box p="15px">
						<Flex alignItems={"center"}>
							<Box
								as="span"
								flex="1"
								textAlign="left"
								color={syWhite}
								fontWeight={"600"}
								fontSize="20px"
							>
								Goal List
							</Box>
							<Flex alignItems={"center"}>
								<Input
									type="search"
									placeholder="Search Goals"
									value={searchTerm}
									onChange={handleSearch} // Handle input change
									color={syWhite}
								/>
								<Switch
									colorScheme="green"
									ml="2"
									isChecked={goalEnable}
									isDisabled={switchDisableFlag}
									onChange={() => {
										setGoalEnable(!goalEnable);
									}}
								/>
								<Button
									colorScheme="green"
									size="sm"
									rightIcon={<FaPlus />}
									onClick={() => {
										onOpen();
										setIconUploadFlag(false);
									}}
									ref={btnRef}
									mx="3"
									w="40%"
								>
									Add Goal
								</Button>
							</Flex>
						</Flex>

						<Box
							borderRadius={"20px"}
							mt="3"
							p="15px"
							h="calc(100vh - 20vh)"
							overflow="auto"
							css={{
								"&::-webkit-scrollbar": {
									width: "4px",
								},
								"&::-webkit-scrollbar-track": {
									width: "6px",
								},
								"&::-webkit-scrollbar-thumb": {
									background: { syGrey },
									borderRadius: "24px",
								},
							}}
						>
							{renderGoalDetails()}
						</Box>
						{totalPages > 1 && (
							<Box>
								<ResponsivePagination
									current={currentPage}
									total={totalPages}
									onPageChange={pageNumber => {
										setCurrentPage(pageNumber);
									}}
								/>
							</Box>
						)}
					</Box>
					{/* Add Upload Goal Drawer */}
					<Drawer
						isOpen={isOpen}
						onClose={() => {
							handleCloseData();
						}}
						size="md"
					>
						<DrawerOverlay />
						<DrawerContent bg={syThemeBgBlueColor}>
							<DrawerHeader
								color={syWhite}
								borderBottomWidth={"0.5px"}
							>
								{iconUploadFlag ? (
									<>
										Upload{" "}
										<Text
											as="span"
											textTransform={"capitalize"}
										>
											{selectedGoalLabel === "icon"
												? "Goal"
												: selectedGoalLabel}
										</Text>{" "}
										Icon
									</>
								) : goalDetails?.goal_uuid ? (
									"Update Goal"
								) : (
									"Add New Goal"
								)}
							</DrawerHeader>
							<DrawerCloseButton color="#d7d7d7" />
							<DrawerBody>
								{iconUploadFlag ? (
									<UploadGoalIcons
										label={selectedGoalLabel}
										goalDetails={goalDetails}
										passSuccessFlag={refreshGoalData}
									/>
								) : (
									<AddUpdateGoal
										passSuccessFlag={refreshGoalData}
										goalDetails={goalDetails}
									/>
								)}
							</DrawerBody>
						</DrawerContent>
					</Drawer>
				</Box>
			)}
		</>
	);
}

export default GoalList;
