// Chakra Imports
import React, { useState, useEffect } from "react";
import { BiDollar } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Select,
	Heading,
	useToast,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CommonWidget from "../../common/CommonWidget";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syGrey,
} from "../../../constants/color";

// Api Imports
import { updateGoalPlan } from "../../../services/goalServices";

function SetupGoal() {
	const toast = useToast();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [loading, isLoading] = useState(false);
	const createdGoalDetails = state?.data;
	const goalDataName = createdGoalDetails?.goal_name;
	const goalPlanningId = state?.goalPlanningId;
	const goalId = createdGoalDetails?.goal_uuid;
	const goalHastag = createdGoalDetails?.goal_tags?.length
		? createdGoalDetails?.goal_tags.split(",")
		: [];
	const todayDate = new Date();
	const [date, setDate] = useState(new Date());
	const [goalAmount, setGoalAmount] = useState(2000);
	const [saveAmount, setSavedAmount] = useState(null);
	const [goalDays, setGoalDays] = useState(0);

	const onChange = date => {
		setDate(date);
	};

	// Todo:Render Hashtag
	const renderHashTag = () => {
		if (goalHastag?.length) {
			return goalHastag.map((data, index) => {
				return (
					<Text
						key={data + index}
						bg={syBlack}
						mr="3"
						mt="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						{data}
					</Text>
				);
			});
		}
	};

	// Todo:Update Plan Details navigate("/genz/goal-overview");
	const updatePlanDetails = () => {
		isLoading(true);
		let subObj = {
			goal_planning_uuid: goalPlanningId,
			goal_uuid: goalId,
			goal_name: goalDataName,
			goal_target_amount: parseInt(goalAmount),
			goal_currency: "'$",
			goal_duration_unit: "week",
			goal_duration_days: goalDays,
			goal_completion_datetime: moment(date)?.format("DD/MM/YYYY"),
			goal_event_amount: parseInt(saveAmount),
		};
		updateGoalPlan(subObj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));
					navigate("/genz/goal-summary", {
						state: {
							data: subObj,
							planningId: goalPlanningId,
						},
					});
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	useEffect(() => {
		calculateAmount();
	}, [date]);

	const calculateAmount = () => {
		if (date <= todayDate) {
			return 0;
		}
		const weeks = calculateWeeks(todayDate, date);
		setSavedAmount((goalAmount / weeks).toFixed(2));
	};

	const calculateWeeks = (start, end) => {
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
		const timeDifference = end.getTime() - start.getTime();
		const totalDays = Math.ceil(timeDifference / millisecondsPerDay);
		setGoalDays(totalDays);
		return Math.ceil(timeDifference / millisecondsPerWeek); // Get number of full weeks
	};

	useEffect(() => {
		if (goalDays?.length > 0) {
			addDaysToDate();
		}
	}, [goalDays]);

	// Function to add input days to current date
	const addDaysToDate = () => {
		const currentDate = new Date(); // Get current date
		const newDate = new Date(currentDate.getTime()); // Clone current date to avoid modification
		// Add goalDays to the cloned date
		newDate.setDate(newDate.getDate() + parseInt(goalDays));
		setDate(newDate); // Update the date state
	};

	return (
		<Box
			bg={syGrey}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box bg={syThemeBgYellowColor} my="1rem" borderRadius={"10px"}>
					<Heading
						fontWeight={"900"}
						textAlign={"center"}
						py="1.5rem"
						fontSize={"26px"}
					>
						{goalDataName}
					</Heading>
				</Box>

				<Flex my="1rem" borderRadius={"10px"} flexWrap={"wrap"}>
					{renderHashTag()}
				</Flex>

				<Box
					mt="1rem"
					mb="4rem"
					px="20px"
					py="30px"
					bg={syThemeBgYellowColor}
					borderRadius={"8px"}
				>
					<Box mb="2rem">
						<Heading
							fontSize={"15px"}
							fontWeight={"900"}
							color={syBlack}
							mb="1rem"
						>
							How much do you want to save in Total?
						</Heading>
						<InputGroup
							border="none"
							bg={syWhite}
							borderRadius={"10px"}
							h="50px"
						>
							<InputLeftElement pointerEvents="none" top="5px">
								<BiDollar />
							</InputLeftElement>
							<Input
								type="number"
								fontWeight={"bold"}
								color={syBlack}
								border="0"
								_hover={{ boxShadow: "none" }}
								textAlign={"center"}
								h="50px"
								value={goalAmount}
								onChange={e => {
									setGoalAmount(e.target.value);
								}}
							/>
						</InputGroup>
					</Box>
					<Box my="2rem">
						<Heading
							fontSize={"15px"}
							fontWeight={"900"}
							color={syBlack}
							mb="1rem"
						>
							How often do you want to do this?
						</Heading>
						<Select
							bg={syWhite}
							size="lg"
							_focus={{
								borderColor: syWhite,
								boxShadow: "none",
							}}
							fontWeight={"bold"}
						>
							<option value="option1">Every Week</option>
							<option value="option2">Once In A Week</option>
							<option value="option3">
								Three Times In A Week
							</option>
							<option value="option3">
								Five Times In A Week
							</option>
						</Select>
					</Box>
					<Box mb="2rem">
						<Heading
							fontSize={"15px"}
							fontWeight={"900"}
							color={syBlack}
							mb="1rem"
						>
							How long do you want to do this?
						</Heading>
						<Flex justifyContent={"space-between"}>
							<Input
								value={goalDays}
								onChange={e => {
									setGoalDays(e.target.value);
								}}
								type="number"
								placeholder="Enter"
								textAlign={"center"}
								fontWeight={"bold"}
								color={syBlack}
								border="0"
								_hover={{ boxShadow: "none" }}
								h="50px"
								w="50%"
								bg={syWhite}
							/>
							<Flex
								alignItems={"center"}
								justifyContent={"center"}
								fontWeight={"bold"}
								color={syBlack}
								border="0"
								_hover={{ boxShadow: "none" }}
								bg={syWhite}
								h="50px"
								w="30%"
							>
								Days
							</Flex>
						</Flex>
					</Box>
					<Box mb="2rem">
						<Heading
							fontSize={"15px"}
							fontWeight={"900"}
							color={syBlack}
							mb="1rem"
							textAlign={"center"}
						>
							Or Save By
						</Heading>
						<Calendar
							onChange={onChange}
							value={date}
							minDate={new Date()}
						/>
					</Box>
					<Box textAlign={"center"}>
						<Button
							borderRadius={"50px"}
							size="lg"
							w="100%"
							bg={syThemeBgBlueColor}
							color={syWhite}
							boxShadow={"xs"}
							onClick={() => {
								updatePlanDetails();
							}}
							isDisabled={
								date.toDateString() ===
								new Date().toDateString()
									? true
									: false
							}
							isLoading={loading ? true : false}
						>
							Create a plan
						</Button>
					</Box>
				</Box>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default SetupGoal;
