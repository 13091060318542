// React Chakra Imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { Img, Box, Flex, Text, Button, Heading } from "@chakra-ui/react";

// Component Imports
import "react-calendar/dist/Calendar.css";
import CommonWidget from "../../common/CommonWidget";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import CompleteGoal from "../../../images/genz/complete-goal.png";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syGrey,
} from "../../../constants/color";

function GoalOverview() {
	const navigate = useNavigate();

	return (
		<Box
			bg={syGrey}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box bg={syThemeBgYellowColor} my="1rem" borderRadius={"10px"}>
					<Heading
						fontWeight={"bolder"}
						textAlign={"center"}
						py="2rem"
						fontSize={"24px"}
					>
						Billie Eilish LA Concert
					</Heading>
				</Box>

				<Flex my="2rem" borderRadius={"10px"}>
					<Text
						bg={syBlack}
						mr="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						#Travel
					</Text>
					<Text
						bg={syBlack}
						mr="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						#Besties
					</Text>
				</Flex>

				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syWhite}
					borderRadius={"8px"}
				>
					<Img src={CompleteGoal} mx="auto" />
					<Heading textAlign={"center"} fontSize={"20px"} mt="2rem">
						Complete the goal to earn the badge!!
					</Heading>
				</Box>
				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syWhite}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						fontSize={"24px"}
						fontWeight={"700"}
						color={syThemeBgBlueColor}
					>
						$ 2,000
					</Heading>
					<Heading
						fontSize={"18px"}
						fontWeight={"700"}
						mt="1rem"
						color={syBlack}
					>
						in
					</Heading>
					<Heading
						fontSize={"24px"}
						fontWeight={"700"}
						mt="1rem"
						color={syThemeBgBlueColor}
					>
						56 days
					</Heading>
				</Box>
				<Box mt="1rem" borderRadius={"8px"} textAlign={"center"}>
					<Heading
						textAlign={"center"}
						fontSize={"20px"}
						fontWeight={"700"}
						color={syBlack}
					>
						=
					</Heading>
				</Box>
				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syThemeBgBlueColor}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						textAlign={"center"}
						fontSize={"20px"}
						fontWeight={"700"}
						color={syThemeBgYellowColor}
					>
						$ 250{" "}
						<Text
							as="span"
							fontSize={"16px"}
							mx="2"
							color={syWhite}
						>
							Every
						</Text>{" "}
						Week
					</Heading>
					<Heading
						textAlign={"center"}
						fontSize={"16px"}
						fontWeight={"700"}
						color={syWhite}
						mt="2rem"
					>
						Edit
					</Heading>
				</Box>
				<Box textAlign={"center"} mt="2rem" mb="4rem">
					<Button
						borderRadius={"50px"}
						size="lg"
						w="80%"
						bg={syThemeBgYellowColor}
						color={syBlack}
						boxShadow={"xs"}
						onClick={() => {
							navigate("/genz/goal-progress");
						}}
					>
						Let's do it
					</Button>
				</Box>
			</Box>

			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default GoalOverview;
