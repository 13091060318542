// React Chakra Imports
import React from "react";
import { Box } from "@chakra-ui/react";

// Component Imports
import { syBlack } from "../../../../constants/color";
import { getUserName } from "../../../../utils/localStorageIndex";

function DreamLife() {
	const userName = getUserName();

	return (
		<Box color={syBlack} fontWeight={"600"}>
			{userName} DreamLife
		</Box>
	);
}

export default DreamLife;
