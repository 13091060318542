// Constant Color
export const syBlack = "#000000";
export const syWhite = "#ffffff";
export const syTitle = "#152237";
export const syGrey = "#F6F7FF";
export const syBgGrey = "#151117";
export const syPurple = "#C383F5";
export const syBorderGrey = "#979797";
export const syArrowGreenColor = "#4ab493";
export const syThemeLabelColor = "#8F94A3";
export const syThemeBgBlueColor = "#5455D1";
export const syThemeBgYellowColor = "#DAF66F";
export const syThemeBgGreenColor = "#BEFF6C";
