// Chakra Imports
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Img,
	Box,
	Flex,
	Text,
	Button,
	Input,
	Heading,
	useToast,
	InputGroup,
	InputRightElement,
	useBreakpointValue,
} from "@chakra-ui/react";

// Component Imports
import {
	syBlack,
	syThemeBgGreenColor,
	syThemeLabelColor,
	syWhite,
} from "../../../constants/color";
import { BiHide, BiShow } from "react-icons/bi";
import backIcon from "../../../images/common/back.png";
import divider from "../../../images/common/divider.png";
import googleIcon from "../../../images/common/googleIcon.png";
import facebookIcon from "../../../images/common/facebookIcon.png";
import { changeDefaultPassword } from "../../../services/sharedServices";
import { toastFunctionToaster } from "../../../utils/toasterFunction";

function DefaultPassword() {
	const toast = useToast();
	const navigate = useNavigate();
	const { state } = useLocation();
	const email = state?.emailId;
	const [show, setShow] = useState(false);
	const [loading, isloading] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [dummyPassword, setDummyPassword] = useState("");
	const [reEnterPassword, SetReEnterPassword] = useState("");
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);
	const [dummyPasswordErr, setDummyPasswordErr] = useState("");
	const [newPasswordErr, setNewPasswordErr] = useState("");
	const [reEnterPasswordErr, setReEnterPasswordErr] = useState("");

	// Todo:Landscape Settings
	const orientation = useBreakpointValue({
		base: "portrait",
		md: "landscape",
	});

	// Todo:Validate Form
	const validateFieldForm = () => {
		let formIsValid = true;
		if (!dummyPassword) {
			formIsValid = false;
			setDummyPasswordErr("*Default Password is required.");
		} else {
			setDummyPasswordErr("");
		}

		if (!newPassword) {
			formIsValid = false;
			setNewPasswordErr("*New Password is required.");
		} else {
			setNewPasswordErr("");
		}

		if (!reEnterPassword) {
			formIsValid = false;
			setReEnterPasswordErr("*Re-Enter New Password required.");
		} else if (newPassword !== reEnterPassword) {
			formIsValid = false;
			setReEnterPasswordErr(
				"*New Password and Re-Enter Password do not match."
			);
		} else {
			setReEnterPasswordErr("");
		}

		return formIsValid;
	};

	const changePassword = () => {
		if (validateFieldForm()) {
			isloading(true);
			let obj = {
				email: email,
				current_password: dummyPassword,
				new_password: newPassword,
			};
			changeDefaultPassword(obj)
				.then(res => {
					isloading(false);
					if (res.result) {
						navigate("/login");
						toast(toastFunctionToaster(res?.message, "success"));
					} else {
						isloading(false);
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					isloading(false);
					if (err)
						toast(toastFunctionToaster(err?.response, "error"));
				});
		}
	};

	return (
		<Box
			bg={syThemeBgGreenColor}
			h={{
				sm: orientation === "portrait" ? "auto" : "auto",
				base: orientation === "portrait" ? "100vh" : "auto",
				md: orientation === "portrait" ? "100vh" : "auto",
				lg: orientation === "portrait" ? "100vh" : "auto",
				xl: orientation === "portrait" ? "100vh" : "auto",
			}}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			mx="auto"
		>
			<Flex w="100%">
				<Img
					src={backIcon}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</Flex>
			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"700"} color={syBlack}>
					Change Password
				</Heading>
				<Text
					pt="1rem"
					fontWeight={"500"}
					fontSize={"14px"}
					color={syBlack}
				>
					<Text as="span" fontSize={"16px"} fontWeight={"600"} mt="2">
						Hi, {email}
					</Text>{" "}
					<Text>Update your dummy password!</Text>
				</Text>
			</Box>

			<Box mt="3rem">
				{/* dummy password */}
				<Box>
					<Box
						bg={syWhite}
						borderRadius={"25px"}
						px="15px"
						position={"relative"}
						mt="1rem"
					>
						<Text
							pl="15px"
							fontSize={"14px"}
							position={"absolute"}
							zIndex={"2"}
							top="5px"
							fontWeight={600}
							color={syThemeLabelColor}
						>
							Default Password
						</Text>
						<InputGroup>
							<Input
								type={show ? "text" : "password"}
								value={dummyPassword}
								name="dummyPassword"
								onChange={e => {
									setDummyPassword(e.target.value);
								}}
								border={"none"}
								_active={{
									boxShadow: "none",
									borderColor: syWhite,
								}}
								pt="5px"
								height={"60px"}
							/>
							<InputRightElement width="4.5rem">
								<Button
									mt="15px"
									h="1.75rem"
									size="sm"
									onClick={() => {
										setShow(!show);
									}}
									bg="none"
									_hover={{ bg: "none" }}
								>
									{show ? (
										<BiHide size="sm" color={syBlack} />
									) : (
										<BiShow size="sm" color={syBlack} />
									)}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Text color="red" mt="2" fontWeight={"600"}>
						{dummyPasswordErr}
					</Text>
				</Box>
				{/* new password */}
				<Box mt="2">
					<Box
						bg={syWhite}
						borderRadius={"25px"}
						px="15px"
						position={"relative"}
						mt="1rem"
					>
						<Text
							pl="15px"
							fontSize={"14px"}
							position={"absolute"}
							zIndex={"2"}
							top="5px"
							fontWeight={600}
							color={syThemeLabelColor}
						>
							New Password
						</Text>
						<InputGroup>
							<Input
								type={showNewPassword ? "text" : "password"}
								value={newPassword}
								name="newPassword"
								onChange={e => {
									setNewPassword(e.target.value);
								}}
								border={"none"}
								_active={{
									boxShadow: "none",
									borderColor: syWhite,
								}}
								pt="5px"
								height={"60px"}
							/>
							<InputRightElement width="4.5rem">
								<Button
									mt="15px"
									h="1.75rem"
									size="sm"
									onClick={() => {
										setShowNewPassword(!showNewPassword);
									}}
									bg="none"
									_hover={{ bg: "none" }}
								>
									{showNewPassword ? (
										<BiHide size="sm" color={syBlack} />
									) : (
										<BiShow size="sm" color={syBlack} />
									)}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Text color="red" mt="2" fontWeight={"600"}>
						{newPasswordErr}
					</Text>
				</Box>
				{/* Re-Enter Password */}
				<Box mt="2">
					<Box
						bg={syWhite}
						borderRadius={"25px"}
						px="15px"
						position={"relative"}
						mt="1rem"
					>
						<Text
							pl="15px"
							fontSize={"14px"}
							position={"absolute"}
							zIndex={"2"}
							top="5px"
							fontWeight={600}
							color={syThemeLabelColor}
						>
							Re-Enter New Password
						</Text>
						<InputGroup>
							<Input
								type={showRePassword ? "text" : "password"}
								value={reEnterPassword}
								name="reEnterPassword"
								onChange={e => {
									SetReEnterPassword(e.target.value);
								}}
								border={"none"}
								_active={{
									boxShadow: "none",
									borderColor: syWhite,
								}}
								pt="5px"
								height={"60px"}
							/>
							<InputRightElement width="4.5rem">
								<Button
									mt="15px"
									h="1.75rem"
									size="sm"
									onClick={() => {
										setShowRePassword(!showRePassword);
									}}
									bg="none"
									_hover={{ bg: "none" }}
								>
									{showRePassword ? (
										<BiHide size="sm" color={syBlack} />
									) : (
										<BiShow size="sm" color={syBlack} />
									)}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Text color="red" mt="2" fontWeight={"600"}>
						{reEnterPasswordErr}
					</Text>
				</Box>

				<Box mt="3rem" textAlign={"center"}>
					<Button
						bg={syBlack}
						color={syWhite}
						_hover={{ bg: syBlack }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							changePassword();
						}}
						isDisabled={loading ? true : false}
						isLoading={loading ? true : false}
					>
						Change Password
					</Button>
					<Text
						mt="2rem"
						textAlign={"center"}
						colorScheme={syBlack}
						textDecoration={"underline"}
						onClick={() => {
							navigate("/login");
						}}
						fontWeight={"600"}
					>
						Login
					</Text>
				</Box>
				<Box mt="3rem">
					<Img src={divider} mx="auto" />
				</Box>
				<Flex justifyContent={"center"} mt="3rem">
					<Img src={googleIcon} mr="3" />
					<Img src={facebookIcon} mr="3" />
				</Flex>
			</Box>
		</Box>
	);
}

export default DefaultPassword;
