// React - Chakra Imports
import React from "react";
import { Img, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

// Component Imports
import Logout from "./Logout";
import backIcon from "../../images/common/back.png";

function CommonHeader({ logoutMl }) {
	const navigate = useNavigate();
	return (
		<Flex w="100%" justifyContent={"space-between"}>
			<Img
				h="25px"
				w="25px"
				src={backIcon}
				onClick={() => {
					navigate(-1);
				}}
			/>
			<Logout
				size={"lg"}
				borderRadius={"50px"}
				ml={logoutMl ? logoutMl : "10"}
				icon={"true"}
			/>
		</Flex>
	);
}

export default CommonHeader;
