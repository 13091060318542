// Chakra Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Img,
	Box,
	Flex,
	Text,
	Button,
	Input,
	Heading,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import backIcon from "../../../images/common/back.png";
import divider from "../../../images/common/divider.png";
import googleIcon from "../../../images/common/googleIcon.png";
import facebookIcon from "../../../images/common/facebookIcon.png";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syBlack,
	syThemeBgGreenColor,
	syWhite,
} from "../../../constants/color";

// Api Imports
import { forgotSystemPassword } from "../../../services/sharedServices";

function ForgotPassword() {
	const toast = useToast();
	const navigate = useNavigate();
	const [emailId, setEmailId] = useState("");
	const [loading, isloading] = useState(false);
	const [emailIdErr, setEmailIdErr] = useState("");

	// Todo:Landscape Settings
	const orientation = useBreakpointValue({
		base: "portrait",
		md: "landscape",
	});

	// Todo:Validate Email Id
	const validateFieldForm = () => {
		let formIsValid = true;
		let emailPattern = new RegExp(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		);
		if (!emailId) {
			formIsValid = false;
			setEmailIdErr("*Email is required.");
		} else if (!emailPattern.test(emailId)) {
			formIsValid = false;
			setEmailIdErr("*Please enter valid email address.");
		} else {
			setEmailIdErr();
		}
		return formIsValid;
	};

	// Todo:Generate Dummy Password
	const generatePassword = () => {
		if (validateFieldForm()) {
			isloading(true);
			let obj = { email: emailId };
			forgotSystemPassword(obj)
				.then(res => {
					isloading(false);
					if (res.result) {
						navigate("/login");
						toast(toastFunctionToaster(res?.message, "success"));
					} else {
						isloading(false);
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					isloading(false);
					if (err)
						toast(toastFunctionToaster(err?.response, "error"));
				});
		}
	};

	return (
		<Box
			bg={syThemeBgGreenColor}
			h={{
				sm: orientation === "portrait" ? "auto" : "auto",
				base: orientation === "portrait" ? "100vh" : "auto",
				md: orientation === "portrait" ? "100vh" : "auto",
				lg: orientation === "portrait" ? "100vh" : "auto",
				xl: orientation === "portrait" ? "100vh" : "auto",
			}}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			mx="auto"
		>
			<Flex w="100%">
				<Img
					src={backIcon}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</Flex>
			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"700"} color={syBlack}>
					Forgot Password
				</Heading>
				<Text my="1rem" fontWeight={"600"} fontSize={"14px"}>
					Please enter your registered email Id
				</Text>
			</Box>

			<Box mt="3rem">
				<Box bg={syWhite} borderRadius={"30px"} px="15px" py="10px">
					<Text pl="15px" fontSize={"12px"}>
						Email
					</Text>
					<Input
						value={emailId}
						onChange={e => {
							setEmailId(e.target.value);
						}}
						type="text"
						fontWeight={"bold"}
						border={"none"}
						_active={{ boxShadow: "none", borderColor: syWhite }}
					></Input>
				</Box>
				<Text color="red" mt="2" fontWeight={"600"}>
					{emailIdErr}
				</Text>

				<Box mt="3rem" textAlign={"center"}>
					<Button
						bg={syBlack}
						color={syWhite}
						_hover={{ bg: syBlack }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							generatePassword();
						}}
						isDisabled={loading ? true : false}
						isLoading={loading ? true : false}
					>
						Generate Dummy Password
					</Button>
					<Text
						mt="2rem"
						textAlign={"center"}
						colorScheme={syBlack}
						textDecoration={"underline"}
						onClick={() => {
							navigate("/login");
						}}
						fontWeight={"600"}
					>
						Login
					</Text>
				</Box>
				<Box mt="3rem">
					<Img src={divider} mx="auto" />
				</Box>
				<Flex justifyContent={"center"} mt="3rem">
					<Img src={googleIcon} mr="3" />
					<Img src={facebookIcon} mr="3" />
				</Flex>
			</Box>
		</Box>
	);
}

export default ForgotPassword;
