import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const fetchGoalsList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().goalList, data, config);
};

export const handleGoalStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().enableDisableGoal, data, config);
};

export const createGoalData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addGoalDetails, data, config);
};

export const updateGoalData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().editGoalDetails, data, config);
};

export const uploadGoalHeadshot = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().uploadGoalHeadshot, data, config);
};

export const fetchUserGoalList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().fetchUserGoal, data, config);
};

export const createGoalPlan = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createGoalPlan, data, config);
};

export const updateGoalPlan = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateGoalPlan, data, config);
};

export const fetchGoalRewardIcon = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().goalRewardIcon, data, config);
};

export const createGoalEvent = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addGoalEvent, data, config);
};
