// React Imports
import React, { useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { Input, Button, Text, Box, useToast, Flex } from "@chakra-ui/react";

// Component Imports
import { syThemeBgYellowColor } from "../../../constants/color";

// Api Imports
import { createGoalData, updateGoalData } from "../../../services/goalServices";

function AddUpdateGoal(props) {
	const toast = useToast();
	const [error, setError] = useState({});
	const [loading, isloading] = useState(false);
	const [goalId, setGoalId] = useState(null);
	const [goalForm, setGoalForm] = useState({
		goal_name: "",
		goal_type: "",
		goal_rewards: "",
		goal_icon: "",
		goal_reward_icon: "",
	});

	// Todo:Update Goal Details
	useEffect(() => {
		if (props?.goalDetails !== null) {
			const goalForm = props?.goalDetails;
			let obj = {
				goal_name: goalForm?.goal_name,
				goal_type: goalForm?.goal_type,
				goal_rewards: goalForm?.goal_rewards,
				goal_icon: goalForm?.goal_icon,
				goal_reward_icon: goalForm?.goal_reward_icon,
			};
			setGoalId(goalForm?.goal_uuid);
			setGoalForm(obj);
		}
	}, [props?.goalDetails]);

	const onUpdateField = e => {
		const nextFormState = {
			...goalForm,
			[e.target.name]: e.target.value,
		};
		setGoalForm(nextFormState);
	};

	const validateFieldForm = () => {
		let fields = goalForm;
		let errors = {};
		let formIsValid = true;

		if (!fields["goal_name"]) {
			formIsValid = false;
			errors["goal_name"] = "*Goal Name is required.";
		}
		if (!fields["goal_type"]) {
			formIsValid = false;
			errors["goal_type"] = "*Goal Type is required.";
		}
		if (!fields["goal_rewards"]) {
			formIsValid = false;
			errors["goal_rewards"] = "*Goal Reward is required.";
		}

		setError(errors);
		return formIsValid;
	};

	const submitUserData = event => {
		event.preventDefault();
		if (validateFieldForm()) {
			isloading(true);
			if (goalId?.length) {
				goalForm.goal_uuid = goalId;
				updateGoalData(goalForm)
					.then(res => {
						if (res.result) {
							isloading(false);
							toast({
								title: res.message,
								status: "success",
								isClosable: true,
								duration: 3000,
							});
							props?.passSuccessFlag("true");
						} else {
							isloading(false);
							toast({
								title: res.message,
								status: "error",
								isClosable: true,
								duration: 3000,
							});
						}
					})
					.catch(err => {
						isloading(false);
					});
			} else {
				createGoalData(goalForm)
					.then(res => {
						if (res.result) {
							isloading(false);
							toast({
								title: res.message,
								status: "success",
								isClosable: true,
								duration: 3000,
							});
							props?.passSuccessFlag("true");
						} else {
							isloading(false);
							toast({
								title: res.message,
								status: "error",
								isClosable: true,
								duration: 3000,
							});
						}
					})
					.catch(err => {
						isloading(false);
					});
			}
		}
	};

	return (
		<Box pt="10px">
			<Box mb="10px">
				<Flex>
					<Text mb="8px" color="#fff">
						Goal Name:
					</Text>
					<CgAsterisk color={"red"} />
				</Flex>
				<Input
					placeholder="Enter Goal Name"
					size="sm"
					borderRadius="10px"
					height={"50px"}
					border="none"
					fontSize="12px"
					borderColor="#494552"
					bg="#1c181e"
					value={goalForm.goal_name}
					name="goal_name"
					onChange={onUpdateField}
					_placeholder={{ color: "white" }}
					color="#fff"
					_hover={{ boxShadow: "none" }}
				/>
				<Text fontSize="sm" color={"red"} mt="1">
					{error?.goal_name}
				</Text>
			</Box>
			<Box mb="10px">
				<Flex>
					<Text mb="8px" color="#fff">
						Goal Type
					</Text>
					<CgAsterisk color={"red"} />
				</Flex>
				<Input
					placeholder="Enter Goal Type"
					size="sm"
					borderRadius="10px"
					height={"50px"}
					border="none"
					fontSize="12px"
					borderColor="#494552"
					bg="#1c181e"
					value={goalForm.goal_type}
					name="goal_type"
					onChange={onUpdateField}
					_placeholder={{ color: "white" }}
					color="#fff"
					_hover={{ boxShadow: "none" }}
				/>
				<Text fontSize="sm" color={"red"} mt="1">
					{error?.goal_type}
				</Text>
			</Box>
			<Box mb="10px">
				<Flex>
					<Text mb="8px" color="#fff">
						Goal Rewards
					</Text>
				</Flex>
				<Input
					placeholder="Enter Goal Rewards"
					size="sm"
					borderRadius="10px"
					height={"50px"}
					border="none"
					fontSize="12px"
					borderColor="#494552"
					bg="#1c181e"
					value={goalForm.goal_rewards}
					name="goal_rewards"
					onChange={onUpdateField}
					_placeholder={{ color: "white" }}
					color="#fff"
					_hover={{ boxShadow: "none" }}
				/>
			</Box>

			<Button
				size="md"
				variant="outline"
				fontWeight="500"
				fontSize="16px"
				w="100%"
				onClick={e => {
					submitUserData(e);
				}}
				type="button"
				isDisabled={loading ? true : false}
				isLoading={loading ? true : false}
				bg={syThemeBgYellowColor}
				border={"none"}
				mt="2rem"
			>
				{goalId?.length ? "Update" : "Add"}
			</Button>
		</Box>
	);
}

export default AddUpdateGoal;
