// React Chakra Imports
import React from "react";
import { Box, Img, Heading } from "@chakra-ui/react";

// Component Imports
import gpayInfoImg from "../../../../images/genz/accordion/bank/gpay.png";
import bankInfoImg from "../../../../images/genz/accordion/bank/bank-info.png";
import cardInfoImg from "../../../../images/genz/accordion/bank/card-details.png";

function UserBankDetails() {
	return (
		<Box>
			<Box>
				<Heading mb="0" fontSize={"14px"} fontWeight={"600"}>
					Transfer from
				</Heading>
				<Box>
					<Img src={cardInfoImg} />
				</Box>
			</Box>
			<Box>
				<Heading mb="0" fontSize={"14px"} fontWeight={"600"}>
					Save to
				</Heading>
				<Box>
					<Img src={gpayInfoImg} />
					<Img src={bankInfoImg} />
				</Box>
			</Box>
		</Box>
	);
}

export default UserBankDetails;
