// Chakra React Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Img,
	Flex,
	Text,
	Heading,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	Spinner,
} from "@chakra-ui/react";

// Component Imports
import WeekCalendar from "./WeekCalendar";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import graph from "../../../images/genz/dashboard/graph.png";
import upIcon from "../../../images/genz/dashboard/up-icon.png";
import {
	syBlack,
	syGrey,
	syWhite,
	syBorderGrey,
	syArrowGreenColor,
	syThemeBgBlueColor,
} from "../../../constants/color";

function SaveUp({ progressData, activeGoals, loading }) {
	const navigate = useNavigate();
	const [sliderValue, setSliderValue] = useState(5);
	const [showTooltip, setShowTooltip] = useState(false);

	const renderActiveGoals = () => {
		if (activeGoals?.length) {
			return activeGoals?.map((data, index) => {
				return (
					<Box
						bg={syGrey}
						p="10px"
						borderRadius={"10px"}
						mb="1.5rem"
						key={index}
						boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
						onClick={() => {
							let obj = {
								goal_uuid: data?.goal_type_uuid,
								goal_name: data?.goal_name,
								goal_reward_icon: data?.goal_reward_icon,
								goal_amount: data?.goal_amount,
								goal_tags: data?.prompt
									? data?.prompt.join(",")
									: [],
							};
							if (!data?.goal_readiness) {
								navigate("/genz/setup-goal", {
									state: {
										data: obj,
										goalPlanningId:
											data?.goal_planning_uuid,
									},
								});
							}
							if (data?.goal_readiness) {
								navigate("/genz/goal-event", {
									state: {
										data: data,
										goalPlanningId:
											data?.goal_planning_uuid,
									},
								});
							}
						}}
					>
						<Flex>
							<Box position={"relative"} mr="1rem">
								<Img
									w="100px"
									h="100px"
									mx="auto"
									src={standImg}
								></Img>
								<Box
									position={"absolute"}
									top="30px"
									left={"30%"}
								>
									<Img w="50%" src={lockImg}></Img>
								</Box>
							</Box>

							<Box>
								<Box>
									<Text
										as="span"
										bg={syBlack}
										color={syWhite}
										borderRadius={"10px"}
										px="2"
										w={"auto"}
									>
										{data?.goal_duration_days
											? data?.goal_duration_days
											: "-"}{" "}
										days left
									</Text>
								</Box>
								<Heading
									fontSize={"18px"}
									mt="1rem"
									fontStyle={"italic"}
								>
									{data?.goal_name}
								</Heading>
								<Slider
									id="slider"
									defaultValue={5}
									min={0}
									max={100}
									colorScheme="blue"
									onChange={v => setSliderValue(v)}
									onMouseEnter={() => setShowTooltip(true)}
									onMouseLeave={() => setShowTooltip(false)}
									my="1rem"
									w="200px"
								>
									<SliderMark
										value={0}
										mt="2"
										ml="0"
										fontSize="sm"
									>
										0
									</SliderMark>
									<SliderMark
										value={25}
										mt="2"
										ml="-2.5"
										fontSize="sm"
									>
										$1000
									</SliderMark>
									<SliderMark
										value={50}
										mt="2"
										ml="1"
										fontSize="sm"
									>
										$1500
									</SliderMark>
									<SliderMark
										value={100}
										mt="2"
										ml="-8"
										fontSize="sm"
									>
										$2000
									</SliderMark>
									<SliderTrack h="15px" borderRadius={"10px"}>
										<SliderFilledTrack />
									</SliderTrack>

									<SliderThumb />
								</Slider>
							</Box>
						</Flex>
					</Box>
				);
			});
		}
	};
	return (
		<Box>
			<Box bg={syWhite} p="20px" borderRadius={"10px"}>
				<Flex justifyContent={"space-between"}>
					<Text color={syBorderGrey} fontWeight={"700"}>
						Total Syval Funds
					</Text>
					<Box>
						<Flex justifyContent={"flex-end"}>
							<Img src={upIcon} transform="rotate(-180deg)" />
							<Text fontWeight={"600"} color={syArrowGreenColor}>
								{progressData?.monthly_growth}
							</Text>
						</Flex>
						<Text fontSize={"12px"} fontWeight={"600"}>
							Compared to last month
						</Text>
					</Box>
				</Flex>

				<Flex justifyContent={"space-between"}>
					<Box mt="2rem">
						<Text
							color={syThemeBgBlueColor}
							fontSize={"26px"}
							fontWeight={"700"}
						>
							$ {progressData?.total_saving}
						</Text>
						<Text fontSize={"12px"} fontWeight={"700"}>
							You are doing amazing!!
						</Text>
					</Box>
					<Box>
						<Img src={graph} />
					</Box>
				</Flex>
			</Box>
			<Box my="2rem">
				<WeekCalendar />
			</Box>
			<Box mb="6rem">
				{loading ? (
					<Flex alignItems="center" justifyContent="center" mt="5rem">
						<Spinner size="xl" color={syWhite} />
					</Flex>
				) : (
					renderActiveGoals()
				)}
			</Box>
		</Box>
	);
}

export default SaveUp;
